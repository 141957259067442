<!-- Settings Drawer -->
<template>
  <div
    class="container px-[24px] pt-2 pb-8 overflow-y-scroll bg-white text-dark-text dark:text-white dark:bg-gray-950 w-full"
  >
    <div class="mb-4 border-b border-gray-100 dark:border-gray-900">
      <ul class="flex flex-wrap -mb-px text-base font-medium text-center" id="myTab">
        <li class="mr-2" role="presentation">
          <button
            class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            :class="{
              'border-blue-500 text-blue-500 dark:border-blue-500': activeTab === 'model',
              'dark:border-gray-600': activeTab !== 'model',
            }"
            id="connection-tab"
            type="button"
            role="tab"
            aria-controls="model"
            aria-selected="false"
            @click="changeTab('model')"
          >
            Model
          </button>
        </li>
        <li class="mr-2" role="presentation">
          <button
            class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            :class="{
              'border-blue-500 text-blue-500 dark:border-blue-500': activeTab === 'evaluation',
              'dark:border-gray-600': activeTab !== 'evaluation',
            }"
            id="connection-tab"
            type="button"
            role="tab"
            aria-controls="evaluation"
            aria-selected="false"
            @click="changeTab('evaluation')"
          >
            Evaluation
          </button>
        </li>
        <li class="mr-2" role="presentation">
          <button
            class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            :class="{
              'border-blue-500 text-blue-500 dark:border-blue-500': activeTab === 'external-assets',
              'dark:border-gray-600': activeTab !== 'external-assets',
            }"
            id="connection-tab"
            type="button"
            role="tab"
            aria-controls="external-assets"
            aria-selected="false"
            @click="changeTab('external-assets')"
          >
            External Assets
          </button>
        </li>
      </ul>
    </div>
    <div id="myTabContent">
      <ModelTab v-if="activeTab === 'model'" />
      <EvaluationTab v-else-if="activeTab === 'evaluation'" />
      <ExternalAssetTab v-else-if="activeTab === 'external-assets'" />
    </div>
  </div>

  <!-- notification toast messages bind it to changes to variable message-->
</template>

<script>
  import { useRouter } from 'vue-router'
  import { useGlobalStore } from './stores/globalStore'
  import ModelTab from '@/components/ModelTab.vue'
  import EvaluationTab from '@/components/EvaluationTab.vue'
  import ExternalAssetTab from '@/components/ExternalAssetTab.vue'

  export default {
    name: 'ModelEvalPage',
    components: {
      ModelTab,
      EvaluationTab,
      ExternalAssetTab,
    },
    setup() {
      const globalStore = useGlobalStore()
      const notify = globalStore.notify
      const router = useRouter()

      const changeTab = tab => {
        router.push(`/${tab}`)
      }

      return {
        changeTab,
        notify,
      }
    },

    computed: {
      activeTab() {
        if (
          this.$route.path.replace('/', '') === 'evaluation/question' ||
          this.$route.path.replace('/', '') === 'evaluation/timestamp'
        ) {
          return 'evaluation'
        }
        return this.$route.path.replace('/', '')
      },
    },
  }
</script>
