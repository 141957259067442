<template>
  <div class="relative w-full">
    <textarea
      :rows="rows"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="placeholder"
      v-bind="$attrs"
      :disabled="disabled"
      class="primary-input w-full resize-none"
      :class="{
        'cursor-not-allowed': disabled,
        'animate-pulse': loading,
        'focus:!ring-red-500 !border-red-500 dark:focus:!ring-red-500 dark:focus:!border-red-500 border dark:text-white':
          hasError,
      }"
    />
    <div class="absolute bottom-0 left-0 w-full h-2 cursor-row-resize bg-transparent" @mousedown="startResizing"></div>
  </div>
</template>
<!-- 
:class="
hasError(item_drawer.sample)
  ? 'focus:!ring-red-500 !border-red-500 dark:focus:!ring-red-500 dark:focus:!border-red-500 border dark:text-white'
  : loading_suggestion && drawerItem.request_id !== item_drawer.request_id
    ? 'border-0 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:text-white animate-pulse'
    : 'border-0 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:text-white'
" -->

<script>
  export default {
    name: 'ResizableTextarea',
    props: {
      modelValue: String,
      placeholder: {
        type: String,
        default: 'Enter your text here...',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      minRows: {
        type: Number,
        default: 2,
      },
      maxRows: {
        type: Number,
        default: 10,
      },
      hasError: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        rows: this.minRows,
        initialY: 0,
        initialRows: this.minRows,
      }
    },
    methods: {
      startResizing(event) {
        this.initialY = event.clientY
        this.initialRows = this.rows
        window.addEventListener('mousemove', this.resize)
        window.addEventListener('mouseup', this.stopResizing)
      },
      resize(event) {
        const diffY = event.clientY - this.initialY
        const rowHeight = 24 // Approximate row height in pixels (adjust as necessary)
        let newRows = this.initialRows + Math.floor(diffY / rowHeight)
        newRows = Math.max(this.minRows, Math.min(newRows, this.maxRows))
        this.rows = newRows
      },
      stopResizing() {
        window.removeEventListener('mousemove', this.resize)
        window.removeEventListener('mouseup', this.stopResizing)
      },
    },
  }
</script>
