<template>
  <div class="h-full">
    <div class="flex flex-col mx-auto relative" :class="eval_mode ? '' : 'h-screen'">
      <button
        @click="scrollToBottom"
        class="fixed right-6 w-8 h-8 flex items-center justify-center rounded-full transition-all duration-200 ease-in text-dark-text dark:text-white dark:hover:bg-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-gray-50 focus:outline-none dark:focus:ring-gray-950"
        :class="{
          'bottom-[120px]': showScrollButton,
          '-bottom-20': !showScrollButton,
        }"
      >
        <LongArrowDownIcon class="w-4 h-4" />
      </button>
      <div class="fixed top-4 right-16" v-if="(!eval_mode && !shared_page) || (is_external_chat && !shared_page)">
        <button
          v-if="messages.length > 1 && !hideShareButton"
          v-tooltip="'Share Chat'"
          @click="show_share_modal = true"
          ref="shareButton"
          class="flex items-center justify-center gap-2 shrink-0 rounded-full p-1.5 px-3 text-sm hover:bg-gray-100 dark:hover:bg-gray-900"
          data-test="5D54F47C-10D8-4905-BF32-0303364995CA"
        >
          <Icon icon="hugeicons:share-05" class="w-5 h-5" />
          <span class="hidden md:inline-block">Share</span>
        </button>
      </div>
      <div ref="scrollableDiv" class="flex-grow h-full pb-[170px] overflow-y-auto">
        <div class="container">
          <div class="flex flex-col max-w-4xl mx-auto px-4" v-if="!eval_mode && !hideTitle">
            <h1
              class="pageTitle text-3xl font-bold mb-2 md:pt-4 px-1 text-center md:text-left outline-none focus:outline-none"
              :contenteditable="!shared_or_external"
              @keydown.enter.exact.prevent="submitTitle"
              @blur="submitTitle"
              ref="title"
            >
              {{ this.shared_chat_title ? this.shared_chat_title : 'Chat with Dot.' }}
            </h1>

            <p
              class="flex items-center justify-center md:justify-start mb-1 pb-4 px-1 text-xs text-gray-400 dark:text-gray-500"
            >
              <span v-if="this.shared_date">
                {{ this.shared_date ? this.shared_date : '' }}
                &nbsp;&nbsp;·&nbsp;&nbsp;
              </span>
              <span v-if="this.author">
                {{ this.author ? this.author : '' }}
              </span>
              <button
                @click="handleOpenScheduleModal()"
                type="button"
                class="hover:text-yellow-400 cursor-pointer"
                v-if="active_schedule?.job_id"
              >
                &nbsp;&nbsp;·&nbsp;&nbsp;Scheduled
              </button>
            </p>
          </div>
          <div
            v-if="
              this.shared_page && !is_external_chat && !(this.author && this.shared_date) && !this.shared_and_loading
            "
            class="flex flex-col max-w-4xl mx-auto px-4"
          >
            <h1 class="text-5xl font-bold mb-2 text-left pt-8 ml-4">Nothing Found.</h1>
            <p class="mb-1 pb-4 text-gray-400 dark:text-gray-500 ml-4">Maybe you have the wrong link?</p>
            <a href="/" class="text-orange font-bold dark:text-yellow-400 hover:underline ml-4">➡️ Go Home</a>
          </div>
          <div v-if="loading_chat && eval_mode" class="h-10"></div>
          <div class="flex flex-row max-w-4xl mx-auto px-4" v-if="loading_chat">
            <p
              class="mb-1 pb-4 text-md text-gray-500 dark:text-gray-400 animate-pulse"
              :class="shared_page ? 'mt-10' : ''"
            >
              Loading Conversation ...
            </p>
          </div>

          <div class="flex flex-col mx-auto max-w-4xl px-1">
            <div class="chat-history">
              <div
                v-if="
                  !eval_mode || (eval_mode && !chatIdIsInUrl) || (eval_mode && chatIdIsInUrl && eval_question_finished)
                "
              >
                <div
                  v-for="(message, index) in formattedMessages"
                  :key="message.html_table || index"
                  class="py-1 px-4 relative"
                >
                  <div
                    class="rounded-md py-2 text-left"
                    :class="{
                      'text-blue': message.role === 'assistant',
                      'mt-8 hover-div pl-0 pr-2': message.role === 'user' && !shared_page,
                      'mt-8 pl-0 pr-2': message.role === 'user' && shared_page,
                    }"
                  >
                    <Answer
                      :message="message"
                      :message_index="index"
                      :messages="messages"
                      :eval_mode="eval_mode"
                      :shared_page="shared_page"
                      :is_external_chat="is_external_chat"
                      :shared_or_external="shared_or_external"
                      :response_loading="response_loading"
                      :currentChatId="currentChatId || $route.query.c || $route.query.cid || ''"
                      @highlight-code="highlightCode"
                      @refresh-message="
                        ({ message_position, html_table, interpretation, timestamp, json_chart }) =>
                          refreshMessage(message_position, html_table, interpretation, timestamp, json_chart)
                      "
                      @dislike-sql="({ message, message_position }) => dislikeSQL(message, message_position)"
                      @update-messages="updateMessagesState"
                      :json_chart="message.json_chart"
                      @add-to-dashboard="message => addToDashboard(message)"
                      @remove-from-dashboard="message => removeFromDashboard(message)"
                      @do-your-best="doYourBest"
                    />
                    <div
                      v-if="message.role !== 'assistant'"
                      class="flex items-center"
                      data-test="3D96C30F-C06B-4032-BD2D-A3FCF762752D"
                    >
                      <div
                        v-if="!edits_enabled[index]"
                        class="whitespace-pre-wrap text-base p-1 w-full focus:outline-none overflow-y-auto"
                        v-dompurify-html="message.content"
                      ></div>
                      <textarea
                        v-else
                        :ref="`editInput-${index}`"
                        class="whitespace-pre-wrap text-base p-1 w-full focus:outline-none overflow-y-auto resize-none bg-transparent border-0 focus:ring-blue-500 focus:ring-2 focus:rounded-xl"
                        :rows="editTextareaRows[index]"
                        v-model="message.content"
                        @input="updateEditTextareaRows(index)"
                        data-test="6DF27A5E-2516-42EB-80CE-9E7D88F4559D"
                      ></textarea>
                      <!-- the edit button for now-->
                      <div
                        v-if="!shared_or_external && !eval_mode && !response_loading && !message.loading_data"
                        class="flex ml-auto cursor-pointer items-center"
                        :class="!edits_enabled[index] && !edits[index] ? 'hidden-svg' : 'shown-svg'"
                      >
                        <CloseIcon
                          v-if="edits_enabled[index]"
                          class="w-5 h-5 opacity-70 text-dark-text dark:text-white text-xs hover:text-blue-500 dark:hover:text-blue-400"
                          @click="cancelEditMessage(message, index)"
                          v-tooltip="'Cancel Edit'"
                        />
                        <button
                          v-tooltip="
                            edits_enabled[index]
                              ? 'Send Message'
                              : edits[index]
                                ? 'Loading. See progess below ⬇️'
                                : 'Edit Message'
                          "
                          class="opacity-70 p-1 text-dark-text dark:text-white rounded-md text-xs hover:text-blue-500 dark:hover:text-blue-400"
                          @click="handleEditMessage(message, index)"
                          :disabled="edits[index]"
                          data-test="FB237513-2822-44F0-A671-7B27EC9A865A"
                        >
                          <!--loading circle-->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5 animate-spin"
                            v-if="edits[index]"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                          </svg>
                          <!-- pencil -->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                            v-else-if="!edits_enabled[index]"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                            />
                          </svg>
                          <!-- submit plane -->
                          <svg
                            v-else
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                            />
                          </svg>
                        </button>

                        <button
                          id="dropdownDelayButton"
                          :data-dropdown-toggle="`dropdownDelayUserMessage${index}`"
                          data-dropdown-delay="100"
                          data-dropdown-trigger="hover"
                          class="opacity-70 p-1 text-dark-text dark:text-white rounded-md text-xs hover:opacity-100"
                          type="button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                            />
                          </svg>
                        </button>

                        <!-- Dropdown menu -->
                        <div
                          :id="`dropdownDelayUserMessage${index}`"
                          class="z-10 hidden bg-white divide-y divide-gray-100 rounded-xl shadow w-44 dark:bg-gray-900"
                        >
                          <ul
                            class="p-1 text-sm text-gray-700 dark:text-gray-200"
                            aria-labelledby="dropdownDelayButton"
                          >
                            <!-- Move section up-->
                            <li>
                              <button
                                v-if="!shared_or_external && index > 0"
                                class="p-2 w-full flex items-center text-dark-text dark:text-white rounded-md text-xs hover:bg-gray-100 dark:hover:bg-gray-800"
                                @click="moveSectionUp(index)"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-5 h-5 mr-2"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18"
                                  />
                                </svg>
                                Move Block Up
                              </button>
                            </li>
                            <!-- Move section down-->
                            <li>
                              <button
                                v-if="!shared_or_external && index < messages.length - 2"
                                class="p-2 w-full flex items-center text-dark-text dark:text-white rounded-md text-xs hover:bg-gray-100 dark:hover:bg-gray-800"
                                @click="moveSectionDown(index)"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-5 h-5 mr-2"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3"
                                  />
                                </svg>
                                Move Block Down
                              </button>
                            </li>
                            <!-- Delete Message -->
                            <li>
                              <button
                                v-if="!shared_or_external && !eval_mode"
                                class="p-2 w-full flex items-center text-red-600 dark:text-red-400 rounded-md text-xs hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-red-700 dark:hover:text-red-300"
                                @click="deleteMessage(index)"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-5 h-5 mr-2"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                Delete Message
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <!-- the end of edit button -->
                    </div>
                    <!-- edit loading section -->
                    <div v-if="edits[index] && chatIdIsInUrl">
                      <div v-if="response_loading && timerCount < 360">
                        <div class="rounded-md py-2 px-4 text-left text-gray-600 dark:text-gray-300 text-blue mt-3">
                          <span class="text-base whitespace-pre-line" v-dompurify-html="this.logs"></span>
                          <br />
                          <span v-if="response_loading" class="animate-pulse">
                            {{ timerCount < 10 ? timerCount.toFixed(1) : timerCount.toFixed(0) }}s
                            {{ timerCount > 60 ? '👀' : '' }}
                            {{ timerCount > 120 ? ' this should be faster..' : '' }}
                          </span>
                        </div>
                      </div>
                      <div
                        v-if="response_loading && timerCount > 360 && !eval_mode && !message.explanation"
                        class="mx-4 my-1 rounded-md py-2 text-left text-gray-600 dark:text-gray-300 text-blue"
                      >
                        <span>
                          I think, I failed you...
                          <br />
                          It could be that I am overloaded, the connected database is too slow or my creators made a
                          mistake.
                          <br />
                          Please try again.
                        </span>
                      </div>
                    </div>
                    <!--               end of edit loading section -->
                    <!-- shows the timestamp of the message if it is set. should be hidden in mobile view -->
                    <div
                      v-if="message.timestamp"
                      :disabled="message.loading_data"
                      class="text-xs text-gray-400 flex dark:text-gray-500 pt-1 pl-2 w-48 cursor-pointer hover:text-gray-800 dark:hover:text-gray-300 transition-all duration-300"
                      v-tooltip.left="{
                        content:
                          `<b>Refresh Data</b><br><small>Last Run: ` +
                          new Date(message.timestamp).toLocaleString() +
                          `</small>`,
                        html: true,
                      }"
                      @click="refreshData(index)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2.0"
                        stroke="currentColor"
                        class="w-4 h-4 mr-2"
                        :class="message.loading_data ? 'animate-spin text-yellow-500 dark:text-yellow-400' : ''"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                        />
                      </svg>
                      <span v-if="!message.loading_data">
                        {{ getTimeAgo(message.timestamp)?.replace('less than a minute ago', 'just now') }}
                      </span>
                      <span v-else class="animate-pulse text-yellow-500 dark:text-yellow-400">Loading...</span>
                    </div>
                  </div>

                  <!--
                The following component displays the feedback_description to the right of the message, if one exists.
                The feedback_description is a comment left by a user when they dislike a message.
                This could be used to explain why they disliked the message and to warn other users.
                It should be hidden in mobile view.
            -->
                  <div class="hidden md:block absolute -right-52 top-2 w-48" v-if="message.feedback === 'dislike'">
                    <div
                      v-if="message.feedback_description"
                      class="flex items-start justify-start text-xs text-gray-400 dark:text-gray-500"
                    >
                      {{ message.feedback_description }}
                    </div>
                  </div>
                </div>
              </div>

              <div :key="logs" v-if="shouldShowLogs" class="py-1 px-4">
                <div class="rounded-md py-2 px-1 text-left text-gray-600 dark:text-gray-300 text-blue">
                  <span class="text-base whitespace-pre-line" v-dompurify-html="this.logs"></span>
                  <br />
                  <span v-if="response_loading" class="animate-pulse">
                    {{ timerCount < 10 ? timerCount.toFixed(1) : timerCount.toFixed(0) }}s
                    {{ timerCount > 60 ? '👀' : '' }}
                    {{ timerCount > 120 ? ' this should be faster..' : '' }}
                  </span>
                </div>
              </div>
              <div
                v-if="(response_loading || !eval_question_finished) && timerCount > 360 && !edittingMessage"
                class="mx-4 my-1 rounded-md py-2 text-left text-gray-600 dark:text-gray-300 text-blue"
              >
                <span>
                  I think, I failed you...
                  <br />
                  It could be that I am overloaded, the connected database is too slow or my creators made a mistake.
                  <br />
                  Please try again.
                </span>
              </div>
            </div>
            <div ref="bottom" class="flex-grow h-36"></div>
            <div
              v-if="shared_page && !is_external_chat"
              class="text-xs py-3 px-4 pl-8 text-gray-400 dark:text-gray-500"
            >
              Chat with
              <a class="hover:underline text-orange" href="https://getdot.ai">Dot.</a>
            </div>
          </div>
        </div>
      </div>
      <div
        ref="chatbox"
        v-if="!shared_or_external && !eval_mode"
        class="absolute pb-3 pt-1 px-5 bottom-0 left-0 right-0 mx-auto max-w-4xl bg-white dark:bg-gray-950"
      >
        <div
          class="pt-2 items-center justify-left space-y-4 sm:flex sm:space-y-0 sm:space-x-4 relative"
          v-if="this.suggested_questions.length > 0"
        >
          <div
            v-if="demo_connected && messages.length === 0 && showSuggestionTooltip"
            class="absolute z-50 tooltip_after flex items-center justify-center py-2 px-4 text-white bg-gray-900 rounded-full shadow-lg animate-bounce !-top-[40px] !left-4 w-max"
            style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
          >
            <p>Click here</p>
            <button @click="showSuggestionTooltip = false" class="ml-4 bg-gray-950 text-white rounded">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div
            data-test="513f5c58-7428-4bcd-beb3-bb75547a32be"
            @click="handleSuggestQuestion(sug_text)"
            v-for="(sug_text, index) in this.suggested_questions"
            :key="sug_text + index"
            :class="sug_text === '' ? 'animate-pulse w-20' : 'sm:w-auto'"
            style="transition: all 0.3s ease-in-out"
            class="w-full bg-opacity-10 cursor-pointer bg-yellow-500 hover:bg-opacity-20 hover:text-yellow-600 dark:hover:text-yellow-200 focus:ring-4 focus:outline-none focus:ring-gray-50 text-yellow-500 dark:text-yellow-400 rounded-full inline-flex items-center justify-center px-3 py-2 dark:bg-opacity-10 dark:bg-yellow-400 dark:focus:ring-yellow-800"
          >
            <div class="text-left">
              <div class="text-sm">
                {{
                  sug_text !== ''
                    ? sug_text.replace('.', '').replace('"', '').replace('"', '').replace('- ', '')
                    : '&nbsp;'
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-3 mt-3">
          <div
            tabindex="0"
            class="flex-row relative flex items-center w-full bg-gray-100 dark:bg-gray-900 rounded-[25px] focus-within:border-orange border-gray-100 dark:border-[#121212] focus:ring-0 focus:outline-none"
            :style="chatIsFocused ? `border-color:` + primaryColor : ''"
            style="transition: border-color 0.3s ease-in-out"
            @focus="chatIsFocused = true"
            @blur="chatIsFocused = false"
            @focusout="chatIsFocused = false"
          >
            <input
              type="file"
              ref="fileInput"
              @change="handleFiles"
              multiple
              hidden
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel sheet.macroEnabled.12, application/vnd.ms-excel.sheet.binary.macroEnabled.12, application/vnd.oasis.opendocument.spreadsheet, application/vnd.google-apps.spreadsheet, text/plain"
            />
            <button
              type="button"
              id="attachment_button"
              v-on:click="triggerFileInput"
              v-tooltip="{
                content: uploading_files
                  ? 'Uploading and Learning ...'
                  : 'Upload CSV or Excel<br><small>only you can access chat uploads</small>',
                html: true,
              }"
              :disabled="user.role !== 'admin' || uploading_files"
              v-if="user.role === 'admin'"
              class="hover:bg-gray-300 dark:hover:bg-[#5a5a5a] w-8 h-8 bottom-2.5 left-3 rounded-full focus:outline-none cursor-pointer flex items-center justify-center absolute group"
            >
              <Icon
                v-if="!uploading_files"
                icon="gg:attachment"
                class="text-gray-500 group-hover:text-gray-700 dark:text-gray-300 group-hover:dark:text-white w-5 h-5"
              />
              <LoadingIcon v-else class="w-5 h-5 text-gray-500 dark:text-gray-300 animate-spin" />
            </button>
            <textarea
              type="text"
              :rows="messageInputRows"
              ref="textarea_user"
              tabindex="0"
              v-model="this.input_message"
              @keydown.enter.exact.prevent="submitMessageNew2"
              @paste="handlePaste"
              @focus="chatIsFocused = true"
              class="w-full overflow-y-auto resize-none bg-transparent py-3.5 text-md border-1 border-none focus:ring-0 focus:outline-none max-h-[200px] max-w-[calc(100%-44px)]"
              :class="{
                'pl-12': user.role === 'admin',
                'pl-6': user.role !== 'admin',
              }"
              placeholder="Ask Dot about your data..."
              :disabled="org.activated_integrations === 0"
              id="chat_input"
            />
            <button
              type="button"
              id="send_button"
              v-on:click="submitMessageNew2"
              v-tooltip="'Send Message'"
              :disabled="org.activated_integrations === 0"
              :style="
                this.input_message?.length > 0
                  ? `opacity: 1.0; background-color:` + primaryColor + '!important'
                  : `opacity: 0.5`
              "
              class="bg-gray-300 dark:bg-[#5a5a5a] w-8 h-8 bottom-2.5 right-3 rounded-full focus:outline-none focus:ring-4 focus:ring-yellow-200 dark:focus:ring-yellow-900 cursor-pointer z-10 flex items-center justify-center absolute"
            >
              <!-- <StopCircleIcon v-if="response_loading" class="w-5 h-5" /> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-5 h-5 text-white dark:text-gray-900"
              >
                <path
                  d="M3.105 2.288a.75.75 0 0 0-.826.95l1.414 4.926A1.5 1.5 0 0 0 5.135 9.25h6.115a.75.75 0 0 1 0 1.5H5.135a1.5 1.5 0 0 0-1.442 1.086l-1.414 4.926a.75.75 0 0 0 .826.95 28.897 28.897 0 0 0 15.293-7.155.75.75 0 0 0 0-1.114A28.897 28.897 0 0 0 3.105 2.288Z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Share modal -->
    <ShareModal
      v-if="show_share_modal"
      :shared_page="shared_page"
      :is_external_chat="is_external_chat"
      :currentChatId="currentChatId"
      :show_modal="show_share_modal"
      :activeTabProp="activeTab"
      @close="show_share_modal = false"
      @update:title="shared_chat_title = $event"
    />

    <!-- <AddToDashboardModal -- on hold for now.
    v-if="show_add_to_dashboard_modal"
    :show_modal="show_add_to_dashboard_modal"
    :currentChatId="currentChatId"
    @close="show_add_to_dashboard_modal = false"
  /> -->

    <DislikeModal
      :message="dislikedMessage?.message"
      :message_position="dislikedMessage?.message_position"
      :chat_id="dislikedMessage?.chat_id"
      @update:message="messages[dislikedMessage?.message_position] = $event"
    />
  </div>
</template>

<script>
  import axios from '@/axiosInstance'
  import mainAxios from 'axios'
  import DataTable from './DataTable.vue'
  import VegaLiteChart from './VegaLiteChart.vue'
  import PlotlyChart from '@/components/PlotlyChart.vue'
  import { initModals, initTabs, initDropdowns } from 'flowbite'
  import { computed, onMounted, ref } from 'vue'
  import { useOrgStore } from '@/stores/orgStore'
  import { useUserStore } from '@/stores/userStore'
  import { useNewChatStore } from '@/stores/chatStore'
  import { storeToRefs } from 'pinia'
  import { useRouter } from 'vue-router'
  import hljs from 'highlight.js'
  import { defineComponent } from 'vue'
  import DislikeModal from './DislikeModal.vue'
  import StopCircleIcon from './icons/StopCircleIcon.vue'
  import { useGlobalStore } from '@/stores/globalStore'
  import CopyIcon from './icons/CopyIcon.vue'
  import CloseIcon from './icons/CloseIcon.vue'
  import { formatDistanceToNow, parseISO, format } from 'date-fns'
  import LongArrowDownIcon from './icons/LongArrowDownIcon.vue'
  import RadioInput from './RadioInput.vue'
  import LoadingIcon from './icons/LoadingIcon.vue'
  import Answer from './Answer.vue'
  import ShareModal from './ShareModal.vue'
  import MarkdownIt from 'markdown-it'
  import markdownItKatex from '@vscode/markdown-it-katex'
  import 'katex/dist/katex.min.css'
  import { Icon } from '@iconify/vue'
  import isValidFileType from '@/hooks/isValidFileType'
  import getTextAreaRows from '@/hooks/getTextareaRows'

  export default defineComponent({
    name: 'ChatComponent',
    props: {
      shared_cid: String, // Simplified prop type
      eval_mode: {
        type: Boolean,
        default: false,
      },
      openedQuestion: {
        type: Object,
        default: null,
      },
    },
    components: {
      PlotlyChart,
      VegaLiteChart,
      DataTable,
      DislikeModal,
      StopCircleIcon,
      CopyIcon,
      CloseIcon,
      LongArrowDownIcon,
      RadioInput,
      LoadingIcon,
      Answer,
      ShareModal,
      Icon,
    },
    setup() {
      const orgStore = useOrgStore()
      const { org } = storeToRefs(orgStore)

      const userStore = useUserStore()
      const { user } = storeToRefs(userStore)

      const newChatStore = useNewChatStore()
      const { currentChatId, ongoingChatId, localMessages } = storeToRefs(newChatStore)

      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      const route = useRouter()
      const isRootRoute = computed(() => route.path === '/')

      const demo_connected = ref(null)
      const dislikedMessage = ref(null)
      const activeTab = ref('share')
      const fileInput = ref(null)
      const uploading_files = ref(false)

      onMounted(() => {
        initTabs()
        initDropdowns()
        initModals()

        orgStore.getConnections(true).then(() => {
          demo_connected.value = orgStore.connections.some(connection => connection.type === 'plugin')
        })
      })

      const handleFiles = e => {
        const files = Array.from(e.target.files)
        const validFiles = files.filter(file => isValidFileType(file, notify))

        if (validFiles.length) uploadFiles(validFiles)
      }

      const triggerFileInput = () => fileInput.value?.click()

      const uploadFiles = files => {
        uploading_files.value = true
        const formData = new FormData()
        files.forEach(file => formData.append('files', file))
        formData.append('is_private', true)

        axios
          .post('/api/upload_files', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then(() => {
            notify.success(
              'Files successfully activated. You can now ask questions about them or configure them in the model section.'
            )
          })
          .catch(error => {
            notify.error(error?.response?.data?.detail || 'Error uploading files')
          })
          .finally(() => (uploading_files.value = false))
      }

      return {
        org,
        user,
        isRootRoute,
        demo_connected,
        dislikedMessage,
        notify,
        currentChatId,
        ongoingChatId,
        localMessages,
        createNewChat: newChatStore.createNewChat,
        setCurrentChatId: newChatStore.setCurrentChatId,
        setOngoingChatId: newChatStore.setOngoingChatId,
        addMessage: newChatStore.addMessage,
        setLocalMessages: newChatStore.setLocalMessages,
        userStore,
        activeTab,
        changeTab: tab => (activeTab.value = tab),
        fileInput,
        uploading_files,
        triggerFileInput,
        handleFiles,
      }
    },
    data() {
      return {
        message: '', // notification message
        isSuccess: true, // notification type
        loading_save: false,
        loading_chat: false,
        messages: [],
        edits: [],
        edits_enabled: [],
        editTextareaRows: [],
        edittingMessage: false,
        response_loading: false,
        rows: 1,
        buttonText: 'Copy Code',
        timerEnabled: false,
        timerCount: 30,
        logs: 'Start.',
        suggested_questions: ['', '', ''],
        input_message: null,
        shared_chat_title: 'Chat with Dot.',
        shared_chat_title_edit: false,
        shared_date: null,
        shared_and_loading: false,
        author: null,
        chatIsFocused: false,
        json_chart: {
          $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
          description: 'A simple bar chart with embedded data.',
          data: {
            values: [
              { a: 'A', b: 28 },
              { a: 'B', b: 55 },
              { a: 'C', b: 43 },
            ],
          },
          mark: 'bar',
          encoding: {
            x: { field: 'a', type: 'ordinal' },
            y: { field: 'b', type: 'quantitative' },
          },
          background: 'transparent',
          autosize: { type: 'fit', resize: true },
          actions: false,
        },
        cancelTokenSource: null,
        messageBeforeEdit: null,
        eval_question_finished: true,
        log_interval_id: null,
        scroll_interval_id: null,
        sharing_chat: false,
        showScrollButton: false,
        showSuggestionTooltip: true,
        show_share_modal: false,
        active_schedule: null,
        show_add_to_dashboard_modal: false,
        timer_interval: null,
      }
    },
    computed: {
      is_external_chat() {
        return ['slack', 'teams'].includes(this.messages?.[0]?.channel)
      },
      shared_page() {
        return Boolean(this.shared_cid)
      },
      shared_or_external() {
        return this.shared_page || this.is_external_chat
      },
      hideShareButton() {
        return new URLSearchParams(window.location.search).get('hideShareButton') === 'true'
      },
      hideTitle() {
        return new URLSearchParams(window.location.search).get('hideTitle') === 'true'
      },
      primaryColor() {
        return new URLSearchParams(window.location.search).get('primaryColor') || 'rgb(180 83 9)'
      },
      formattedMessages() {
        return this.messages.map((m, index, arr) => {
          const is_clarifying_question =
            !m.html_table &&
            (m.explanation?.includes('?') || m.explanation?.toLowerCase()?.includes('specify')) &&
            index === arr.length - 1

          return { ...m, explanation: this.addCopySQLButton(m.explanation), is_clarifying_question }
        })
      },
      chatIdIsInUrl() {
        if (this.eval_mode && this.openedQuestion) {
          return this.openedQuestion.status === 'running'
        }

        return this.$route.query.c === this.ongoingChatId && this.$route.query.c === this.currentChatId
      },
      messageInputRows() {
        return getTextAreaRows(this.input_message, 1)
      },
      shouldShowLogs() {
        return (
          (this.response_loading || !this.eval_question_finished) &&
          this.timerCount < 360 &&
          !this.edittingMessage &&
          this.chatIdIsInUrl &&
          this.logs
        )
      },
    },
    created() {
      document.addEventListener('click', this.handleClick)

      this.handleScroll()
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleClick)
      document.removeEventListener('scroll', this.handleScroll)
    },
    mounted() {
      document.title = 'Chat · Dot'
      this.$refs.scrollableDiv.addEventListener('scroll', this.handleScroll)

      this.messages.forEach((_, index) => {
        this.editTextareaRows[index] = 1
      })

      if (!this.shared_page) {
        const userStore = useUserStore()

        if (!this.$route.query.c) {
          const { currentConversation } = storeToRefs(userStore)

          if (currentConversation.value && currentConversation.value.length) {
            this.messages = currentConversation.value
            this.fetchSuggestions()
          }
        }

        this.$nextTick(() => {
          initTabs()
          initDropdowns()
          this.handleScroll()
          this.highlightCode()
        })

        if (this.messages.length === 0 && !this.eval_mode) {
          this.fetchSuggestions()
        }
      } else {
        this.setCurrentChatId(this.shared_cid)
        const org_id = this.$route.query.org_id
        this.shared_and_loading = true
        this.loading_chat = true
        axios
          .get('/api/share/' + this.currentChatId + '?org_id=' + org_id, {
            withCredentials: true,
          })
          .then(response => {
            this.loading_chat = false
            this.shared_and_loading = false
            if (response.data) {
              this.shared_chat_title = response.data.title
              this.author = response.data.user_id
              const date = parseISO(response.data.date)
              this.shared_date = format(date, 'yyyy-MM-dd HH:mm')

              const data_messages = response.data.messages

              // parse the json_chart string to json for role assistant
              data_messages.forEach((message, index) => {
                if (message.role === 'assistant' && message.json_chart) {
                  // check if json_chart is string or json
                  if (typeof message.json_chart === 'string') {
                    data_messages[index].json_chart = JSON.parse(message.json_chart)
                  }
                }
                if (message.role === 'assistant' && message.explanation) {
                  data_messages[index].explanation = this.replace_backticks(message.explanation)
                  if (message.logs) {
                    data_messages[index].logs = this.replace_backticks(message.logs)
                  }
                }
              })

              this.messages = data_messages

              // set document title and meta tags
              if (this.shared_chat_title) {
                document.title = this.shared_chat_title + ' · Dot'
                this.setMetaTag('property', 'og:title', this.shared_chat_title)
                this.setMetaTag('property', 'og:description', `by ${this.author} on ${this.shared_date}`)
                this.setMetaTag(
                  'property',
                  'og:image',
                  'https://img.freepik.com/fotos-kostenlos/lebhafter-verschwommener-bunter-tapetenhintergrund_58702-4377.jpg'
                )
              }

              this.$nextTick(() => {
                initTabs()
                initDropdowns()
                this.highlightCode()
                initModals()
              })
            }
          })
      }
    },
    methods: {
      markdown_urls_to_links(text) {
        // Add an icon to external links
        // Use UTF-8 character for external link icon
        return text.replace(
          /\[(.*?)\]\((.*?)\)/g,
          `<a class="externalLink" target="_blank" href="$2">
            $1
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/><polyline points="15 3 21 3 21 9"/><line x1="10" y1="14" x2="21" y2="3"/></svg>
          </a>`
        )
      },
      submitTitle() {
        this.shared_chat_title_edit = true
        this.shared_chat_title = this.$refs.title.innerText
      },
      getTimeAgo(dateString) {
        if (!dateString) return ''
        const date = new Date(dateString) // Make sure the input date is treated as UTC

        // No need to convert to user's timezone if you just want to find the elapsed time
        // Because elapsed time is the same regardless of timezone

        // Calculate the time ago
        return formatDistanceToNow(date, { addSuffix: true })
      },
      handleScroll() {
        const scrollableDiv = this.$refs.scrollableDiv
        if (!scrollableDiv) return

        const atBottom = scrollableDiv.scrollHeight - scrollableDiv.scrollTop === scrollableDiv.clientHeight
        this.showScrollButton = !atBottom

        if (!this.$refs.chatbox) return
        if (atBottom) {
          this.$refs.chatbox.classList.remove('border-t', 'primary-border')
        } else {
          this.$refs.chatbox.classList.add('border-t', 'primary-border')
        }
      },
      resetChat() {
        if (this.shared_page || this.eval_mode || this.messages.length < 2) return
        if (this.chatIdIsInUrl) return

        this.messages = []

        let currentQuery = Object.assign({}, this.$route.query)
        // if there's an ongoing chat id, use it
        currentQuery.c = this.$route.path === '/new' && this.ongoingChatId ? this.ongoingChatId : this.currentChatId

        this.$router.push({ query: currentQuery })
        this.shared_chat_title = null
        this.shared_date = null
        this.author = null
        this.fetchSuggestions()
      },
      highlightCode() {
        const blocks = document.querySelectorAll('pre#code')
        blocks.forEach(block => {
          block.removeAttribute('data-highlighted')
          const code = block.textContent
          block.innerHTML = this.escapeHTML(code)
          this.$nextTick(() => {
            hljs.highlightBlock(block)
          })
        })
      },
      handlePaste(event) {
        // Wait for the next tick to ensure the model is updated
        this.$nextTick(() => {
          this.input_message = this.input_message?.trim()
        })
      },
      fetchSuggestions() {
        if (this.messages.length === 0 && (this.demo_connected || this.demo_connected === null)) {
          this.suggested_questions = ['Show me revenue by month', 'How many items sold per payment method per month?']
          return
        }

        const user_messages = this.messages
          .filter(message => message.role === 'user')
          .map(message => message.content)
          .join('\n')
        const table_id = this.messages.find(message => message.role === 'assistant')?.table_id

        axios
          .post('/api/get_question_suggestions', { user_messages, table_id }, { withCredentials: true })
          .then(response => {
            if (response.data) this.suggested_questions = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      replace_backticks(explanation) {
        // Ensure explanation is a string
        if (typeof explanation !== 'string') {
          // try getting log_content attribute if explanation is an object
          if (typeof explanation === 'object' && explanation.log_contents) {
            explanation = explanation.log_contents
          } else {
            console.warn('replace_backticks was called with a non-string argument')
            return explanation
          }
        }

        if (!explanation) return

        explanation = explanation
          .replace(/```([\s\S]*?)```/g, '<pre id="code">$1</pre>')

          // .replace(/```([^`]+)```/g, '<pre id="code">$1</pre>')
          .replace(/<pre id="code">sql/gi, '<pre id="code" class="language-sql">')
          .replace(/<pre id="code">python/gi, '<pre id="code" class="language-python">')
          .replace(/\n<pre id="code">/g, '<pre id="code">')
          .replace(/<pre>/g, '<pre id="code">') // also replace <pre> with <pre id="code">
          .replace(/`([^`]+)`/g, '<code>$1</code>')
          .replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>') // replace things between **something** with <strong> tags

        return explanation
      },
      async submitMessageNew2() {
        if (!this.input_message || this.response_loading) return

        this.showSuggestionTooltip = false
        this.setLocalMessages(this.messages)

        if (!this.currentChatId) {
          if (this.$route.query.c) {
            this.setCurrentChatId(this.$route.query.c)
          } else {
            this.createNewChat()
            const currentQuery = { ...this.$route.query, c: this.currentChatId }
            this.$router.push({ query: currentQuery })
          }
        }
        this.setOngoingChatId(this.currentChatId)

        if (this.ongoingChatId === this.$route.query.c) {
          this.addMessage({ role: 'user', content: this.input_message })
          this.messages = [...this.localMessages]
        }

        const is_first_message = this.messages.length === 1

        if (is_first_message) {
          this.userStore.updateChatMessages({
            first_question: this.input_message,
            date: new Date().toISOString(),
            id: this.ongoingChatId,
          })
        }

        this.input_message = null
        this.suggested_questions = ['', '', '']
        this.response_loading = true
        this.rows = 1
        this.timerCount = 0
        this.timerEnabled = true

        this.$nextTick(() => this.$refs?.bottom?.scrollIntoView({ behavior: 'smooth' }))
        this.$refs.textarea_user.focus()

        sessionStorage.setItem('questionRunning', true)

        try {
          // fetch logs at least 3 times in case of failure
          this.fetchLogs(1, 3)

          const response = await this.askQuestion(this.ongoingChatId, [...this.localMessages])

          response.explanation = this.replace_backticks(response.explanation)
          if (response.logs) response.logs = this.replace_backticks(response.logs)
          response.json_chart = response.json_chart?.replace('v5.8.0', 'v5.6.1')

          this.response_loading = false

          if (this.ongoingChatId === this.$route.query.c) {
            this.addMessage({
              role: 'assistant',
              json_chart: response.json_chart ? JSON.parse(response.json_chart) : null,
              html_table: response.html_table,
              interpretation: response.interpretation,
              query: response.query,
              explanation: response.explanation,
              filter_text: response.filter_text,
              logs: response.logs,
              sources: response.sources,
              table_id: response.table_id,
              timestamp: response.timestamp,
            })

            this.messages = [...this.localMessages]
          }

          this.userStore.getChatMessages()
          this.fetchSuggestions()
          if (is_first_message) this.suggestTitle()

          this.$nextTick(() => {
            initTabs()
            initDropdowns()
            initModals()
            this.highlightCode()
          })
        } catch (error) {
          console.log(error)
        } finally {
          // this.timerEnabled = false
          // this.response_loading = false
        }
      },
      async submitEditedMessage(message, index) {
        try {
          this.cancelTokenSource = mainAxios.CancelToken.source()

          // replace the last message with the edited message
          this.messages.splice(index, 1, message)

          // get all messages up to the edited message
          const messages = this.messages.slice(0, index + 1)

          if (!this.currentChatId) {
            this.createNewChat()
            const currentQuery = { ...this.$route.query, c: this.currentChatId }
            this.$router.push({ query: currentQuery })
          }

          this.edits[index] = true
          this.edittingMessage = true
          this.setOngoingChatId(this.currentChatId)
          sessionStorage.setItem('questionRunning', true)

          this.response_loading = true
          this.timerCount = 0
          this.timerEnabled = true

          this.fetchLogs(1, 3)

          let response = await axios.post(
            `/api/ask`,
            { messages, chat_id: this.ongoingChatId },
            { withCredentials: true, cancelToken: this.cancelTokenSource.token }
          )

          this.response_loading = false
          this.edits[index] = false
          this.edittingMessage = false
          response = response.data

          response.explanation = this.replace_backticks(response.explanation)
          if (response.logs) response.logs = this.replace_backticks(response.logs)
          this.messages.splice(index + 1, 1, {
            role: 'assistant',
            json_chart: response.json_chart ? JSON.parse(response.json_chart) : null,
            html_table: response.html_table,
            interpretation: response.interpretation,
            query: response.query,
            explanation: response.explanation,
            filter_text: response.filter_text,
            logs: response.logs,
            sources: response.sources,
            table_id: response.table_id,
            timestamp: response.timestamp,
          })
          this.$nextTick(() => {
            initTabs()
            initDropdowns()
            initModals()
            this.highlightCode()
          })
        } catch (error) {
          console.log(error)
          this.edits[index] = false
        }
      },
      async askQuestion(chat_id, messages) {
        try {
          this.cancelTokenSource = mainAxios.CancelToken.source()
          let response0 = await axios.post(
            `/api/ask`,
            { messages, chat_id },
            { withCredentials: true, cancelToken: this.cancelTokenSource.token }
          )
          return response0.data
        } catch (error) {
          if (mainAxios.isCancel(error)) {
            console.log('Request canceled', error.message)
            this.fetchSuggestions()
          } else {
            console.log(error)
          }
        }
      },
      stopQuery() {
        this.cancelTokenSource?.cancel('Query aborted by user.')
        this.response_loading = false
        this.timerCount = 0
        this.logs = 'Query aborted by user.'
        this.cancelTokenSource = null
        this.messages = this.messages.map(m => ({ ...m, edit_mode: false }))
      },
      likeSQL(query_text, message, message_position) {
        let modified_message = message
        modified_message.feedback = message.feedback === 'like' ? '' : 'like'

        this.messages.splice(message_position, 1, modified_message)

        const last_human_message = this.messages.filter(msg => msg.role === 'user').slice(-1)[0].content

        axios
          .post(
            '/api/save_query_doc',
            {
              query: { question_text: last_human_message, sql_text: query_text },
              table_id: message.table_id,
              generate_title: true,
            },
            { withCredentials: true }
          )
          .then(() => {
            this.loading_save = false
          })
          .catch(error => {
            console.log(error)
            this.loading_save = false
          })

        axios
          .post('/api/feedback', { chat_id: this.currentChatId, message, message_position }, { withCredentials: true })
          .then(() => {
            this.loading_save = false
          })
          .catch(error => {
            console.log(error)
            this.loading_save = false
          })
      },
      dislikeSQL(message, message_position) {
        this.dislikedMessage = { message, message_position, chat_id: this.currentChatId }
      },
      deleteMessage(message_position) {
        axios
          .post('/api/deleteMessage', { chat_id: this.currentChatId, message_position }, { withCredentials: true })
          .then(() => {
            this.messages.splice(message_position, 1)
            this.$nextTick(() => {
              initTabs()
              initDropdowns()
            })
          })
          .catch(error => {
            console.log(error)
          })
      },
      moveSectionUp(message_position) {
        if (message_position === 0 || message_position === this.messages.length - 1) return

        const [message, following_message] = this.messages.splice(message_position, 2)
        this.messages.splice(message_position - 2, 0, message)
        this.messages.splice(message_position - 1, 0, following_message)

        axios
          .post(
            '/api/moveMessage',
            { chat_id: this.currentChatId, message_position, direction_up: true },
            { withCredentials: true }
          )
          .catch(error => {
            console.log(error)
          })
      },
      moveSectionDown(message_position) {
        if (message_position === 0 || message_position === this.messages.length - 1) return

        const [preceding_message, message] = this.messages.splice(message_position - 1, 2)
        this.messages.splice(message_position, 0, preceding_message)
        this.messages.splice(message_position, 0, message)

        axios
          .post(
            '/api/moveMessage',
            { chat_id: this.currentChatId, message_position, direction_up: false },
            { withCredentials: true }
          )
          .catch(error => {
            console.log(error)
          })
      },
      refreshData(message_position) {
        const chat_id = this.currentChatId

        if (this.messages[message_position]) {
          this.messages[message_position].loading_data = true
        }
        axios
          .post('/api/rerun_query', { chat_id, message_position })
          .then(response => {
            response.data.json_chart = response.data.json_chart?.replace('v5.8.0', 'v5.6.1')

            const message = this.messages[message_position]
            Object.assign(message, {
              html_table: response.data.html_table,
              interpretation: response.data.interpretation,
              timestamp: response.data.timestamp,
              json_chart: response.data.json_chart ? JSON.parse(response.data.json_chart) : null,
              loading_data: false,
            })

            this.$nextTick(() => {
              initTabs()
              initDropdowns()
              this.highlightCode()
            })
          })
          .catch(error => {
            console.log(error)
            this.messages[message_position].loading_data = false
          })
      },
      refreshMessage(message_position, html_table, interpretation, timestamp, json_chart) {
        Object.assign(this.messages[message_position], {
          html_table,
          interpretation,
          timestamp,
          json_chart: json_chart ? JSON.parse(json_chart) : null,
        })
        this.$nextTick(() => {
          initTabs()
          initDropdowns()
          this.highlightCode()
        })
      },
      updateMessagesState(messages) {
        this.messages = messages
        this.$nextTick(() => {
          initTabs()
          initDropdowns()
          this.highlightCode()
        })
      },
      downloadTableData(message_position, chat_id, htmlTableString) {
        const rows = htmlTableString.match(/<tr[^>]*>(.*?)<\/tr>/gs)
        this.notify.info('Downloading data...')
        if (rows.length >= 5000) {
          axios
            .get('/api/download_big_csv', { params: { chat_id, message_position } })
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              document.body.appendChild(link)
              link.href = url
              link.setAttribute('download', 'data.csv')
              link.click()
              document.body.removeChild(link)
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.downloadCSV(htmlTableString, 'data.csv')
        }
      },
      tableToCSV(htmlTableString) {
        const rows = htmlTableString.match(/<tr[^>]*>(.*?)<\/tr>/gs)
        return rows
          .map(row => {
            const headerCells = row.match(/<th[^>]*>(.*?)<\/th>/gs) || []
            const normalCells = row.match(/<td[^>]*>(.*?)<\/td>/gs) || []
            return [...headerCells, ...normalCells].map(cell => cell.replace(/<[^>]+>/g, '')).join(',')
          })
          .join('\n')
      },
      downloadCSV(htmlTableString, filename) {
        const csv = this.tableToCSV(htmlTableString)
        const blob = new Blob([csv], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = filename
        link.target = '_blank'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      },
      suggestTitle(share = false) {
        if (!this.shared_chat_title || this.shared_chat_title === 'Chat with Dot.') {
          this.sharing_chat = true
          const user_messages = this.messages
            .filter(message => message.role === 'user')
            .map(message => message.content)
            .join('\n')
          axios
            .post('/api/suggest_title', { user_messages }, { withCredentials: true })
            .then(response => {
              this.shared_chat_title_edit = true
              this.shared_chat_title = response.data
              this.loading_save = false
            })
            .catch(error => {
              console.log(error)
              this.loading_save = false
            })
        }
      },
      async startFetchingLogs(chat_id) {
        return new Promise(async (resolve, reject) => {
          try {
            this.logs = 'Initializing...'

            // wait 1s for the server to start the logs
            await new Promise(r => setTimeout(r, 1000))

            let finished = false

            this.log_interval_id = setInterval(async () => {
              if (finished || this.timerCount > 360) {
                this.timerEnabled = false
                this.response_loading = false
                clearInterval(this.log_interval_id)
                resolve({ status: 'timeout', message: 'Log fetching timed out or exceeded maximum duration.' })
                return
              }

              try {
                const response = await axios.get(`/api/logs/${chat_id}`)
                this.logs = this.replace_backticks(response.data.log_contents)
                finished = response.data.finished
                this.eval_question_finished = response.data.finished

                if (this.eval_mode && finished) {
                  this.setOngoingChatId(null)
                  if (this.$route.query.c !== this.openedQuestion.run_id && this.openedQuestion.run_id) {
                    this.$router.push({ query: { c: this.openedQuestion.run_id } })
                  }
                }

                if (finished) {
                  sessionStorage.setItem('questionRunning', false)
                  if (chat_id === this.$route.query.c) {
                    this.fetchConversation(chat_id)
                  }
                  clearInterval(this.log_interval_id)
                  resolve(response.data) // Resolve with the response if logs are finished
                }

                this.$nextTick(() => this.highlightCode())
              } catch (error) {
                console.log(error)
                clearInterval(this.log_interval_id)
                reject(error) // Reject with the error
              }
            }, 2000)

            setTimeout(() => {
              if (!finished) {
                clearInterval(this.log_interval_id)
                resolve({ status: 'timeout', message: 'Log fetching timed out after 120 seconds.' })
              }
            }, 120 * 1000)
          } catch (error) {
            console.log(error)
            reject(error) // Reject with the error in case of any outer catch block error
          }
        })
      },

      fetchLogs(retries, maxRetries) {
        this.startFetchingLogs(this.currentChatId).catch(error => {
          console.error('An error occurred:', error)
          clearInterval(this.log_interval_id)

          if (retries < maxRetries) {
            console.log(`Retrying (${retries}/${maxRetries})...`)
            fetchLogs(retries + 1, maxRetries)
          } else {
            console.error(`Failed after ${maxRetries} retries. Logging disabled.`)
          }
        })
      },
      handleSuggestQuestion(question) {
        this.input_message = question
        this.submitMessageNew2()
        this.suggested_questions = ['', '', '']
      },
      handleEditMessage(message, index) {
        if (!this.edits_enabled[index]) {
          this.messageBeforeEdit = { ...this.messageBeforeEdit, [index]: message.content }
          this.edits_enabled[index] = true
          this.$nextTick(() => {
            const editInput = this.$refs[`editInput-${index}`][0]
            if (editInput) editInput.focus()
          })
        } else {
          this.edits_enabled[index] = false
          this.submitEditedMessage(message, index)
        }
      },
      cancelEditMessage(message, index) {
        this.edits_enabled[index] = false
        message.content = this.messageBeforeEdit[index]
        this.messages.splice(index, 1, message)
      },
      copySQL(code) {
        if (!code) return
        navigator.clipboard.writeText(code).then(
          () => {
            this.notify.success('Copied!')
          },
          err => {
            console.error('Could not copy SQL code: ', err)
            this.notify.error('Failed to copy SQL code to clipboard')
          }
        )
      },
      addCopySQLButton(message) {
        if (!message) return ''

        const copyButton = `
    <button class="absolute top-[60px] cursor-pointer right-1 h-5 w-5 z-10" data-action="copyCode">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 hover:text-blue-500 dark:hover:text-blue-400 text-gray-500 dark:text-gray-400">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
      </svg>
    </button>
  `

        const md = new MarkdownIt({ html: true }).use(markdownItKatex)
        const regexDisplayLatex = /\\\[([\s\S]*?)\\\]/g
        const regexInlineLatex = /\\\(([\s\S]*?)\\\)/g
        const regexSQL = /<pre.*?>([\s\S]*?)<\/pre>/g

        // Function to process LaTeX content
        const processLatex = (latexContent, isDisplay) => {
          const escapedLatex = latexContent
            .replace(/\\text\{([^}]+)\}/g, '\\mathrm{$1}')
            .replace(/_/g, '\\_')
            .trim()

          return isDisplay ? `$$${escapedLatex}$$` : `$${escapedLatex}$`
        }

        // Step 1: Process display LaTeX
        let formattedText = message.replace(regexDisplayLatex, (match, latexGroup) => {
          if (latexGroup) {
            console.log('Display LaTeX:', latexGroup)
            return md.render(processLatex(latexGroup, true))
          }
          return match
        })

        // Step 2: Process inline LaTeX
        formattedText = formattedText.replace(regexInlineLatex, (match, latexGroup) => {
          if (latexGroup) {
            console.log('Inline LaTeX:', latexGroup)
            return md.render(processLatex(latexGroup, false))
          }
          return match
        })

        // Step 3: Process SQL and add the copy button
        formattedText = formattedText.replace(regexSQL, (match, sqlGroup) => {
          const preContent = sqlGroup.trim()
          return `<div class="relative code-parent">${copyButton}<pre id="code" class="language-sql m-0">${preContent}</pre></div>`
        })

        return formattedText
      },
      handleClick(event) {
        const buttonOrParent = event.target.closest('[data-action="copyCode"]')
        const sql = event.target.closest('div.code-parent')?.querySelector('pre')?.innerText
        if (buttonOrParent) this.copySQL(sql)
      },
      fetchConversation(chat_id) {
        if (this.$route.query.c !== chat_id || !chat_id || this.loading_chat) return

        this.loading_chat = true
        axios
          .get('/api/c2/' + chat_id, { withCredentials: true })
          .then(response => {
            this.loading_chat = false
            this.active_schedule = response.data.schedules ? response.data.schedules[0] : null

            if (response.data?.messages) {
              response.data.messages.forEach((message, index) => {
                if (message.role === 'assistant' && message.json_chart) {
                  response.data.messages[index].json_chart =
                    typeof message.json_chart === 'string' ? JSON.parse(message.json_chart) : message.json_chart
                }
                if (message.role === 'assistant' && message.explanation) {
                  response.data.messages[index].explanation = this.replace_backticks(message.explanation)
                  if (message.logs) response.data.messages[index].logs = this.replace_backticks(message.logs)
                }
              })

              this.messages = response.data.messages.map(message => {
                if (message.role === 'assistant' && message.json_chart?.$schema) {
                  return {
                    ...message,
                    json_chart: {
                      ...message.json_chart,
                      $schema: message.json_chart.$schema.replace('v5.8.0', 'v5.6.1').replace('v5.17.0', 'v5.6.1'),
                    },
                  }
                }
                return message
              })
              this.shared_chat_title_edit = false
              this.shared_chat_title = response.data.title
              if (this.shared_chat_title) {
                document.title = this.shared_chat_title + ' · Dot'
                this.author = response.data.user_id
                const date = parseISO(response.data.date)
                this.shared_date = format(date, 'yyyy-MM-dd HH:mm')
                this.setMetaTag('property', 'og:title', this.shared_chat_title)
                this.setMetaTag('property', 'og:description', `by ${this.author} on ${this.shared_date}`)
                this.setMetaTag(
                  'property',
                  'og:image',
                  'https://img.freepik.com/fotos-kostenlos/lebhafter-verschwommener-bunter-tapetenhintergrund_58702-4377.jpg'
                )
              }
              this.fetchSuggestions()

              this.$nextTick(() => {
                initTabs()
                initDropdowns()
                initModals()
                this.highlightCode()
              })
            }
          })
          .catch(error => {
            console.log(error)
            this.loading_chat = false
          })
      },
      setMetaTag(attrName, attrValue, content) {
        let element = document.querySelector(`meta[${attrName}='${attrValue}']`)
        if (element) {
          element.setAttribute('content', content)
        } else {
          element = document.createElement('meta')
          element.setAttribute(attrName, attrValue)
          element.setAttribute('content', content)
          document.head.appendChild(element)
        }
      },
      doYourBest() {
        this.input_message = 'Do your best'
        this.$nextTick(() => {
          this.submitMessageNew2()
        })
      },
      scrollToBottom() {
        this.$refs?.bottom?.scrollIntoView({ behavior: 'smooth' })
      },
      load_chats() {
        axios
          .get('/api/chat_history_all')
          .then(response => {
            this.showSuggestionTooltip = response.data.length === 0
          })
          .catch(error => {
            console.log(error)
          })
      },
      escapeHTML(html) {
        return html.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
      },
      handleOpenScheduleModal() {
        this.$refs?.shareButton?.click()
        this.$nextTick(() => {
          this.changeTab('schedule')
        })
      },
      addToDashboard(message) {
        this.show_add_to_dashboard_modal = true
      },
      updateEditTextareaRows(index) {
        const textarea = this.$refs[`editInput-${index}`]
        if (textarea && textarea[0]) {
          const text_message = textarea[0].value
          this.editTextareaRows.splice(index, 1, getTextAreaRows(text_message))
        }
      },
    },
    watch: {
      '$route.query.c': {
        handler(chat_id) {
          this.active_schedule = null

          if (!this.eval_mode) {
            const questionRunning = JSON.parse(sessionStorage.getItem('questionRunning'))

            if (questionRunning && this.ongoingChatId && chat_id === this.ongoingChatId) {
              this.messages = JSON.parse(sessionStorage.getItem('localMessages'))
              this.response_loading = true
              if (chat_id === this.ongoingChatId && this.currentChatId !== chat_id) {
                this.setCurrentChatId(chat_id)
              }
              this.startFetchingLogs(this.ongoingChatId).then(res => {
                if (res && res.finished) {
                  this.fetchConversation(chat_id)
                  sessionStorage.setItem('questionRunning', false)
                  clearInterval(this.log_interval_id)
                  this.response_loading = false
                }
              })
            } else if (this.$route.path === '/new') {
              this.shared_chat_title = null
              this.shared_date = null
              this.author = null
              clearInterval(this.log_interval_id)
              this.messages = []
              this.logs = ''
              this.response_loading = false
            } else {
              this.setCurrentChatId(chat_id)
              this.fetchConversation(chat_id)
            }
          } else if (chat_id) {
            clearInterval(this.log_interval_id)
            this.timerCount = 0
            if (chat_id && !chat_id.startsWith('er')) return

            if (!this.openedQuestion) return

            this.setOngoingChatId(chat_id)
            if (this.openedQuestion.runs && this.openedQuestion.runs[0]?.status === 'running') {
              this.startFetchingLogs(chat_id).then(finished => {
                if (finished) {
                  this.fetchConversation(chat_id)
                  this.eval_question_finished = true
                  clearInterval(this.log_interval_id)
                }
              })
              return
            }

            if (this.openedQuestion.runs && this.$route.query.timestamp) {
              this.fetchConversation(chat_id)
              this.eval_question_finished = true
              clearInterval(this.log_interval_id)
              return
            }

            if (this.openedQuestion.status === 'running') {
              this.eval_question_finished = false
              this.startFetchingLogs(chat_id)
            } else {
              this.fetchConversation(chat_id)
              this.eval_question_finished = true
              clearInterval(this.log_interval_id)
            }
          }
        },
        immediate: true,
      },
      $route: {
        handler() {
          if (this.eval_mode) return

          if (!this.$route.query.c && !this.shared_page) {
            this.createNewChat()
            const currentQuery = { ...this.$route.query, c: this.currentChatId }
            this.$router.push({ query: currentQuery })
            this.setLocalMessages([])
            this.shared_chat_title = null
            this.shared_date = null
            this.author = null
          }
        },
        immediate: true,
      },
      messages: {
        handler(newMessages) {
          const userStore = useUserStore()
          const { currentConversation } = storeToRefs(userStore)
          if (currentConversation) currentConversation.value = newMessages

          if (this.editTextareaRows.length !== newMessages.length) {
            this.editTextareaRows = newMessages.map((_message, index) => {
              const textarea = this.$refs[`editInput-${index}`]
              return textarea && textarea[0] ? getTextAreaRows(textarea[0].value) : 1
            })
          }
        },
        immediate: true,
      },
      timerEnabled(value) {
        if (value) {
          clearInterval(this.timer_interval)
          this.timer_interval = setInterval(() => {
            this.timerCount = this.timerCount + 0.1
            if (this.timerCount > 360) {
              this.timerEnabled = false
              this.response_loading = false
            }
          }, 100)
        } else {
          clearInterval(this.timer_interval)
        }
      },
      shared_chat_title(value) {
        if (this.shared_chat_title_edit && value) {
          axios.post(
            '/api/change_title',
            { chat_id: this.currentChatId, title: this.shared_chat_title },
            { withCredentials: true }
          )

          document.title = value + ' · Dot'

          const userStore = useUserStore()
          const { chatMessages } = storeToRefs(userStore)
          const message = chatMessages.value.find(msg => msg.id === this.currentChatId)
          if (message) message.title = this.shared_chat_title
        }
      },
      currentChatId() {
        this.resetChat()
      },
      openedQuestion(value, oldValue) {
        this.timerCount = 0

        if (value && this.eval_mode) {
          if (value.id !== oldValue?.id) {
            clearInterval(this.log_interval_id)
            this.messages = []
            this.logs = ''
          }

          if (value.status === 'running') {
            this.eval_question_finished = false
          } else {
            this.fetchConversation(value.run_id)
            clearInterval(this.log_interval_id)
          }
        } else {
          clearInterval(this.log_interval_id)
          this.eval_question_finished = true
        }
      },
      demo_connected(value) {
        if (value === false && value !== null) {
          this.fetchSuggestions()
        }
      },
    },
  })
</script>

<style lang="scss">
  @import '../index.css';

  .pageTitle {
    /* Remove outline on contenteditabel focus */
    outline: none;
  }

  .externalLink {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #c27803;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }

    svg {
      margin-left: 0.1em;
      margin-top: -0.5em;
      height: 0.7rem;
      width: 0.7rem;
      stroke-linejoin: round;
      stroke-linecap: round;
      stroke-width: 2;
      stroke: currentColor;
      fill: none;
    }
  }

  pre {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: 'Roboto Mono', Consolas, monospace !important;
    font-size: 14px !important;
    opacity: 1;
    /*background-color: rgba(136, 117, 96, 0.1);*/
    border-radius: 10px;
    padding: 10px;
    /*margin: 10px 0px !important;*/
    /*margin-top: 10px !important;*/
    border: 1px solid rgba(138, 133, 127, 0.13);
    overflow: auto;
    max-height: 300px;
  }

  code {
    color: rgb(180, 83, 9);
    font-family: 'Roboto Mono', Consolas, monospace !important;
    font-size: 14px !important;
    padding: 2px 3px 1px;
    border-radius: 3px;
    background-color: rgba(136, 117, 96, 0.09);
    border: 1px solid rgba(136, 117, 96, 0.13);
  }

  .dark code {
    color: orange;
  }

  /* light code theme */
  /* based on stackoverflow light */
  .hljs {
    /* var(--highlight-color) */
    color: #1c1a18;
    /* var(--highlight-bg) */
    /*background: rgba(136, 117, 96, 0.1);*/
  }

  .hljs-subst {
    /* var(--highlight-color) */
    color: #1c1a18;
  }

  .hljs-comment {
    /* var(--highlight-comment) */
    color: #776d65;
  }

  .hljs-keyword,
  .hljs-selector-tag,
  .hljs-meta .hljs-keyword,
  .hljs-doctag,
  .hljs-section {
    /* var(--highlight-keyword) */
    color: rgb(8, 91, 215);
  }

  .hljs-attr {
    /* var(--highlight-attribute); */
    color: rgb(8, 91, 215);
  }

  .hljs-attribute {
    /* var(--highlight-symbol) */
    color: #5b3cb5;
  }

  .hljs-name,
  .hljs-type,
  .hljs-number,
  .hljs-selector-id,
  .hljs-quote,
  .hljs-template-tag {
    /* var(--highlight-namespace) */
    color: rgb(180, 83, 9);
  }

  .hljs-symbol,
  .hljs-selector-class {
    /* var(--highlight-keyword) */
    color: rgb(8, 91, 215);
  }

  .hljs-string,
  .hljs-regexp,
  .hljs-variable,
  .hljs-template-variable,
  .hljs-link,
  .hljs-selector-attr {
    /* var(--highlight-variable) */
    /*color: #6c3cb5;*/
    color: rgb(180, 83, 9);
  }

  .hljs-meta,
  .hljs-selector-pseudo {
    /* var(--highlight-keyword) */
    color: rgb(8, 91, 215);
  }

  .hljs-built_in,
  .hljs-title,
  .hljs-literal {
    /* var(--highlight-literal) */
    color: rgb(180, 83, 9);
  }

  .hljs-bullet,
  .hljs-code {
    /* var(--highlight-punctuation) */
    color: #535a60;
  }

  .hljs-meta .hljs-string {
    /* var(--highlight-variable) */
    color: #54790d;
  }

  .hljs-deletion {
    /* var(--highlight-deletion) */
    color: #c02d2e;
  }

  .hljs-addition {
    /* var(--highlight-addition) */
    color: #2f6f44;
  }

  .hljs-emphasis {
    font-style: italic;
  }

  .hljs-strong {
    font-weight: bold;
  }

  .hljs-formula,
  .hljs-operator,
  .hljs-params,
  .hljs-property,
  .hljs-punctuation,
  .hljs-tag {
    /* purposely ignored */
  }

  /* based on stackoverflow dark */

  .dark .hljs {
    /* var(--highlight-color) */
    color: #fff4ea;
    /* var(--highlight-bg) */
    /*background: rgba(136, 117, 96, 0.1);*/
  }

  .dark .hljs-subst {
    /* var(--highlight-color) */
    color: #1c1a18;
  }

  .dark .hljs-comment {
    /* var(--highlight-comment) */
    color: #776d65;
  }

  .dark .hljs-keyword,
  .hljs-selector-tag,
  .hljs-meta .hljs-keyword,
  .hljs-doctag,
  .hljs-section {
    /* var(--highlight-keyword) */
    color: rgb(118, 169, 250);
  }

  .dark .hljs-attr {
    /* var(--highlight-attribute); */
    color: rgb(118, 169, 250);
  }

  .dark .hljs-attribute {
    /* var(--highlight-symbol) */
    color: #6543cc;
  }

  .dark .hljs-name,
  .dark .hljs-type,
  .dark .hljs-number,
  .dark .hljs-selector-id,
  .dark .hljs-quote,
  .dark .hljs-template-tag {
    /* var(--highlight-namespace) */
    color: orange;
  }

  .dark .hljs-selector-class,
  .dark .hljs-symbol {
    /* var(--highlight-keyword) */
    color: rgb(118, 169, 250);
  }

  .dark .hljs-string,
  .dark .hljs-regexp,
  .dark .hljs-variable,
  .dark .hljs-template-variable,
  .dark .hljs-link,
  .dark .hljs-selector-attr {
    /* var(--highlight-variable) */
    /*color: #6c3cb5;*/
    color: orange;
  }

  .dark .hljs-meta,
  .dark .hljs-selector-pseudo {
    /* var(--highlight-keyword) */
    color: rgb(118, 169, 250);
  }

  .dark .hljs-built_in,
  .dark .hljs-title,
  .dark .hljs-literal {
    /* var(--highlight-literal) */
    color: orange;
  }

  .hljs-bullet,
  .hljs-code {
    /* var(--highlight-punctuation) */
    color: #535a60;
  }

  .hljs-meta .hljs-string {
    /* var(--highlight-variable) */
    color: #54790d;
  }

  .hljs-deletion {
    /* var(--highlight-deletion) */
    color: #c02d2e;
  }

  .hljs-addition {
    /* var(--highlight-addition) */
    color: #2f6f44;
  }

  /* edit button */
  /* Initially hide the SVG */
  .hidden-svg {
    opacity: 0;
    transition: opacity 0.2s;
  }

  .shown-svg {
    opacity: 1;
    transition: opacity 0.2s;
  }

  /* Show the SVG when hovering over the outer div */
  .hover-div:hover .hidden-svg {
    opacity: 1;
  }

  /* full logs open/close */
  .transition-max-height {
    transition: max-height 0.3s ease-in-out;
  }

  button {
    transition: all 0.3s;
  }

  button[aria-selected='true'].answertab {
    color: #131313 !important;
    border-bottom: 1px solid #131313 !important;
  }

  button[aria-selected='false'].answertab {
    color: #939393;
    border-bottom: 1px solid transparent !important;
  }

  .dark button[aria-selected='true'].answertab {
    color: #eeeeee !important;
    border-bottom: 1px solid #eeeeee !important;
  }

  .dark button[aria-selected='false'].answertab {
    color: #757575;
    border-bottom: 1px solid transparent !important;
  }

  /* add hover effect to the buttons */
  button[aria-selected='false'].answertab:hover {
    color: #464646 !important;
    border-bottom: 1px solid #464646 !important;
  }

  .dark button[aria-selected='false'].answertab:hover {
    color: #b0b0b0 !important;
    border-bottom: 1px solid #b0b0b0 !important;
  }

  .box__bg {
    position: relative;
    z-index: 0;
    overflow: hidden;
    padding: 1px;
  }

  .box__bg::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 250%;
    background-image: linear-gradient(#f000, #f38900);
    animation: bgRotate 4s linear infinite;
  }

  .dark .box__bg::before {
    background-image: linear-gradient(#f000, #ffb100);
  }

  .tooltip_after::after {
    content: '';
    position: absolute;
    top: 90%;
    left: 10%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #212121 transparent transparent transparent;
  }

  @keyframes bgRotate {
    100% {
      transform: rotate(1turn);
    }
  }
</style>
