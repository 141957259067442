<template>
  <div>
    <aside
      class="h-full"
      :class="{
        'w-0': !showSidebar,
        'w-[260px] fixed md:relative top-0 left-0 z-10': showSidebar,
      }"
    >
      <button
        class="fixed rounded-16 bg-white dark:bg-gray-950 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-900 w-8 h-8 flex flex-col justify-center items-center top-4 transition-transform duration-300 ease-in left-6 group"
        :class="{
          'translate-x-0': !showSidebar,
          '-translate-x-[100px]': showSidebar,
        }"
        @click="toggleSidebar"
      >
        <Icon icon="hugeicons:menu-04" class="w-6 h-6 text-dark-text dark:text-white" />
      </button>
      <div
        class="min-w-[260px] w-[260px] max-h-full h-full bg-[#F5F5F5] dark:bg-[#1D1D1D] transition-transform duration-200 ease-in relative flex flex-col"
        :class="{
          '-translate-x-full': !showSidebar,
          'translate-x-0': showSidebar,
        }"
      >
        <div class="flex justify-end px-5 py-4">
          <button class="cursor-pointer group" @click="toggleSidebar">
            <Icon
              icon="hugeicons:sidebar-left"
              class="w-6 h-6 text-icon-gray-light group-hover:text-dark-text dark:group-hover:text-white duration-300 transition-colors ease-in-out"
            />
          </button>
        </div>
        <div class="top-0 left-0 flex flex-col h-full relative z-[40]" aria-label="Sidenav">
          <ul
            class="space-y-1 pb-2 mb-2 border-gray-200 dark:border-gray-800 px-2.5 relative z-10"
            ref="topLinksElement"
            id="topLinksElement"
          >
            <li @click.stop="newChat" id="chat-nav">
              <router-link
                to="/new"
                :class="[
                  {
                    '!font-semibold bg-gray-200 dark:bg-gray-900': route.name === 'New Chat',
                  },
                  linkIconStyles,
                ]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                  />
                </svg>
                <span :class="linkStyles">New chat</span>
              </router-link>
            </li>
            <li v-if="user.role === 'admin'" id="history-nav">
              <router-link
                to="/history"
                :class="[
                  {
                    '!font-semibold bg-gray-200 dark:bg-gray-900': route.name === 'History',
                  },
                  linkIconStyles,
                ]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                  />
                </svg>
                <span :class="linkStyles">History</span>
              </router-link>
            </li>
            <li v-if="user.role === 'admin'" id="model-nav">
              <router-link
                to="/model"
                :class="[
                  {
                    '!font-semibold bg-gray-200 dark:bg-gray-900':
                      route.path.includes('model') ||
                      route.path.includes('evaluation') ||
                      route.path.includes('external-assets'),
                  },
                  linkIconStyles,
                ]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span :class="linkStyles">Model</span>
              </router-link>
            </li>
            <li v-if="user.role === 'admin'" id="settings-nav">
              <router-link
                to="/settings"
                :class="[
                  {
                    '!font-semibold bg-gray-200 dark:bg-gray-900': route.path.includes('settings'),
                  },
                  linkIconStyles,
                ]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                  />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <span :class="linkStyles">Settings</span>
              </router-link>
            </li>
          </ul>

          <div
            class="overflow-y-hidden h-full absolute bottom-0 left-0 w-full z-[9]"
            :class="{
              'pt-[180px]': user.role === 'admin' || !user,
              'pt-[50px]': user.role !== 'admin',
            }"
          >
            <div class="h-full max-h-full w-full px-3 overflow-y-auto overflow-x-hidden">
              <div v-if="pinnedChatMessages.length" class="mt-2 mb-2">
                <div class="p-1 px-2 font-normal text-gray-400 dark:text-gray-500 text-xs">Favorites</div>
                <ul>
                  <li
                    v-for="chatMessage in pinnedChatMessages"
                    :key="chatMessage.id"
                    v-tooltip.top="chatMessage.title ? chatMessage.title : chatMessage.first_question"
                    class="text-sm flex items-center justify-between gap-2 relative"
                    :class="[
                      {
                        'font-semibold bg-gray-200 dark:bg-gray-900': chatMessage.id === route.query.c,
                      },
                      {
                        '!p-0 hover:!bg-transparent': editTitleId === chatMessage.id,
                      },
                      linkIconStyles,
                    ]"
                  >
                    <router-link
                      :to="{
                        path: '/',
                        query: {
                          c: chatMessage.id,
                        },
                      }"
                      class="ellipsis"
                    >
                      <span
                        v-if="editTitleId === chatMessage.id"
                        @click.stop
                        @click.prevent
                        :contenteditable="editTitleId === chatMessage.id"
                        :id="`title-${chatMessage.id}`"
                        :value="
                          editTitleId === chatMessage.id
                            ? editTitle
                            : chatMessage.title
                              ? chatMessage.title
                              : chatMessage.first_question
                        "
                        @input="editTitle = $event.target.innerText"
                        class="relative primary-input w-full block !p-2 !outline-none focus:!outline-none focus-visible:!outline-none focus-visible:overflow-auto focus:overflow-auto focus:!text-clip ellipsis hide-scrollbar"
                      >
                        {{ chatMessage.title ? chatMessage.title : chatMessage.first_question }}
                      </span>
                      <span v-else>
                        {{ chatMessage.title ? chatMessage.title : chatMessage.first_question }}
                      </span>
                    </router-link>

                    <!-- Save edit title button -->
                    <button
                      v-if="editTitleId === chatMessage.id"
                      class="w-5 h-5 center-child hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-100 ease-in shrink-0 rounded-8 group-hover:flex"
                      @click="renameConversation(chatMessage.id)"
                    >
                      <Icon icon="mingcute:check-fill" class="w-4 h-4 text-header-text-light dark:text-white" />
                    </button>

                    <!-- Trigger Button for Dropdown -->
                    <button
                      v-else
                      class="w-5 h-5 center-child hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-100 ease-in shrink-0 rounded-8 group-hover:flex"
                      :class="{
                        flex: route.query.c === chatMessage.id,
                        hidden: route.query.c !== chatMessage.id,
                      }"
                      :id="`quickactions-${chatMessage.id}`"
                      type="button"
                      @click="showQuickActionsDropdownMenu(chatMessage, $event)"
                    >
                      <Icon icon="akar-icons:more-vertical" class="w-4 h-4 text-header-text-light dark:text-white" />
                    </button>

                    <!-- Flowbite Dropdown -->
                    <div
                      :id="`quickactions-dropdown-${chatMessage.id}`"
                      className="!fixed rounded-16 bg-white dark:bg-gray-ds-400 !z-[99]"
                      v-if="activeQuickActionsDropdown === chatMessage.id && showActiveQuickActionsDropdown"
                      :style="{
                        top: `${quickActionsDropdownPosition.top}px`,
                        left: `${quickActionsDropdownPosition.left}px`,
                      }"
                    >
                      <div
                        class="relative w-max rounded-16 border dark:border-[#e7e7e71a] text-12 font-medium before:absolute before:-left-2 before:top-1/2 before:h-4 before:w-4 before:-translate-y-1/2 before:rotate-45 before:border-l before:border-b before:dark:border-[#e7e7e71a] before:bg-white before:dark:bg-gray-ds-400 before:content-[''] before:z-[97]"
                      >
                        <ul class="text-sm text-gray-700 dark:text-gray-200 relative z-[98]">
                          <li>
                            <button
                              type="button"
                              class="flex w-full items-center gap-3 px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-[#EAEAEB80] dark:hover:bg-gray-700 rounded-t-[16px]"
                              @click="handleQuickAction(chatMessage, 'pin')"
                            >
                              <Icon icon="hugeicons:star-off" class="w-4 h-4 text-header-text-light dark:text-white" />
                              <span>Remove from Favorites</span>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="flex w-full items-center gap-3 px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-[#EAEAEB80] dark:hover:bg-gray-700"
                              @click="handleQuickAction(chatMessage, 'rename')"
                            >
                              <Icon
                                icon="hugeicons:pencil-edit-01"
                                class="w-4 h-4 text-header-text-light dark:text-white"
                              />
                              <span>Rename Title</span>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="flex w-full items-center gap-3 px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-[#EAEAEB80] dark:hover:bg-gray-700"
                              @click="handleQuickAction(chatMessage, 'share')"
                            >
                              <Icon icon="hugeicons:share-05" class="w-4 h-4 text-header-text-light dark:text-white" />
                              <span>Share Conversation</span>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="flex w-full items-center gap-3 px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-[#EAEAEB80] dark:hover:bg-gray-700 rounded-b-[16px]"
                              @click="handleQuickAction(chatMessage, 'schedule')"
                            >
                              <Icon icon="hugeicons:clock-01" class="w-4 h-4 text-header-text-light dark:text-white" />
                              <span>Schedule Conversation</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div v-for="(chatMessages, date) in chatMessagesByDate" :key="date">
                <div
                  v-tooltip="{
                    content: `<small>${new Date(chatMessages[0]?.date).toLocaleString()}</small>`,
                    html: true,
                  }"
                  class="font-normal text-gray-400 dark:text-gray-500 text-xs p-1 px-2 mt-4"
                >
                  {{ date }}
                </div>
                <ul class="w-full">
                  <li
                    v-for="chatMessage in chatMessages"
                    :key="chatMessage.id"
                    v-tooltip.top="chatMessage.title ? chatMessage.title : chatMessage.first_question"
                    class="text-sm flex items-center justify-between gap-2 relative"
                    :class="[
                      {
                        'font-semibold bg-gray-200 dark:bg-gray-900': chatMessage.id === route.query.c,
                      },
                      {
                        '!p-0 hover:!bg-transparent': editTitleId === chatMessage.id,
                      },
                      linkIconStyles,
                    ]"
                  >
                    <router-link
                      :to="{
                        path: '/',
                        query: {
                          c: chatMessage.id,
                        },
                      }"
                      class="ellipsis"
                    >
                      <span
                        v-if="editTitleId === chatMessage.id"
                        @click.stop
                        @click.prevent
                        :contenteditable="editTitleId === chatMessage.id"
                        :id="`title-${chatMessage.id}`"
                        :value="
                          editTitleId === chatMessage.id
                            ? editTitle
                            : chatMessage.title
                              ? chatMessage.title
                              : chatMessage.first_question
                        "
                        @input="editTitle = $event.target.innerText"
                        class="relative primary-input w-full block !p-2 !outline-none focus:!outline-none focus-visible:!outline-none focus-visible:overflow-auto focus:overflow-auto focus:!text-clip ellipsis hide-scrollbar"
                      >
                        {{ chatMessage.title ? chatMessage.title : chatMessage.first_question }}
                      </span>
                      <span v-else>
                        {{ chatMessage.title ? chatMessage.title : chatMessage.first_question }}
                      </span>
                    </router-link>

                    <!-- Save edit title button -->
                    <button
                      v-if="editTitleId === chatMessage.id"
                      class="w-5 h-5 center-child hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-100 ease-in shrink-0 rounded-8 group-hover:flex mr-2"
                      @click="renameConversation(chatMessage.id)"
                    >
                      <Icon icon="mingcute:check-fill" class="w-4 h-4 text-header-text-light dark:text-white" />
                    </button>

                    <!-- Trigger Button for Dropdown -->
                    <button
                      v-else
                      class="w-5 h-5 center-child hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-100 ease-in shrink-0 rounded-8 group-hover:flex"
                      :class="{
                        flex: route.query.c === chatMessage.id,
                        hidden: route.query.c !== chatMessage.id,
                      }"
                      :id="`quickactions-${chatMessage.id}`"
                      type="button"
                      @click="showQuickActionsDropdownMenu(chatMessage, $event)"
                    >
                      <Icon icon="akar-icons:more-vertical" class="w-4 h-4 text-header-text-light dark:text-white" />
                    </button>

                    <!-- Flowbite Dropdown -->
                    <div
                      :id="`quickactions-dropdown-${chatMessage.id}`"
                      className="!fixed rounded-16 bg-white dark:bg-gray-ds-400 !z-[99] w-max"
                      v-if="activeQuickActionsDropdown === chatMessage.id && showActiveQuickActionsDropdown"
                      :style="{
                        top: `${quickActionsDropdownPosition.top}px`,
                        left: `${quickActionsDropdownPosition.left}px`,
                      }"
                    >
                      <div
                        class="relative w-max rounded-16 border dark:border-[#e7e7e71a] text-12 font-medium before:absolute before:-left-2 before:top-1/2 before:h-4 before:w-4 before:-translate-y-1/2 before:rotate-45 before:border-l before:border-b before:bg-white before:dark:bg-gray-ds-400 before:content-[''] before:z-[97] before:dark:border-[#e7e7e71a]"
                      >
                        <ul class="text-sm text-gray-700 dark:text-gray-200 relative z-[98] font-medium">
                          <li>
                            <button
                              type="button"
                              class="flex w-full items-center gap-3 px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-[#EAEAEB80] dark:hover:bg-gray-700 rounded-t-[16px]"
                              @click="handleQuickAction(chatMessage, 'pin')"
                            >
                              <Icon icon="hugeicons:star" class="w-4 h-4 text-header-text-light dark:text-white" />
                              <span>Add to Favorites</span>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="flex w-full items-center gap-3 px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-[#EAEAEB80] dark:hover:bg-gray-700"
                              @click="handleQuickAction(chatMessage, 'rename')"
                            >
                              <Icon
                                icon="hugeicons:pencil-edit-01"
                                class="w-4 h-4 text-header-text-light dark:text-white"
                              />
                              <span>Rename Title</span>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="flex w-full items-center gap-3 px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-[#EAEAEB80] dark:hover:bg-gray-700"
                              @click="handleQuickAction(chatMessage, 'share')"
                            >
                              <Icon icon="hugeicons:share-05" class="w-4 h-4 text-header-text-light dark:text-white" />
                              <span>Share Conversation</span>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="flex w-full items-center gap-3 px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-[#EAEAEB80] dark:hover:bg-gray-700 rounded-b-[16px]"
                              @click="handleQuickAction(chatMessage, 'schedule')"
                            >
                              <Icon icon="hugeicons:clock-01" class="w-4 h-4 text-header-text-light dark:text-white" />
                              <span>Schedule Conversation</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          ref="dropdownTrigger"
          @click="toggleShowDropdown"
          class="bg-[#F5F5F5] dark:bg-[#1D1D1D] absolute bottom-0 left-0 z-[40] justify-center w-full text-gray-400 dark:text-gray-500 text-center cursor-pointer px-5 py-5 hover:bg-gray-200 dark:hover:bg-gray-900"
        >
          <button class="w-full text-header-text-light dark:text-white text-center text-14 capitalize" type="button">
            <span class="inline font-semibold">{{ user.realname ? user.realname : '···' }}</span>
          </button>

          <!-- Dropdown menu -->
          <div
            ref="dropdownMenu"
            @click.stop
            class="absolute -top-[160px] left-0 w-max rounded-16 border dark:border-[#e7e7e71a] text-14 font-medium rounded-16 bg-white dark:bg-gray-ds-400 z-[40] overflow-hidden shadow-sm"
            :class="showDropdown ? 'block' : 'hidden'"
          >
            <div class="px-4 py-3 border-t primary-border text-sm text-gray-900 dark:text-white w-64">
              <div class="font-sm truncate">{{ user.id }}</div>
              <div class="font-xs truncate font-mono text-gray-400 dark:text-gray-600">
                {{ user.org_id }}
              </div>
            </div>
            <ul class="text-sm text-gray-700 dark:text-gray-200 relative z-[98] font-medium">
              <li>
                <router-link
                  to="/password"
                  class="flex justify-center w-full items-center gap-3 px-4 py-3 transition-colors duration-300 ease-in-out hover:bg-[#EAEAEB80] dark:hover:bg-gray-700"
                >
                  <span>Reset password</span>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/logout"
                  class="flex w-full justify-center items-center gap-3 px-4 py-3 transition-colors duration-300 ease-in-out hover:bg-[#EAEAEB80] dark:hover:bg-gray-700"
                >
                  <span>Sign out</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
    <!-- Share modal -->
    <ShareModal
      v-if="show_share_modal"
      :shared_page="false"
      :is_external_chat="false"
      :currentChatId="share_chat_id"
      :show_modal="show_share_modal"
      :activeTabProp="active_share_tab"
      @close="show_share_modal = false"
    />
  </div>
</template>

<script>
  import { onMounted, ref, watch, computed, onBeforeUnmount } from 'vue'
  import { initDropdowns } from 'flowbite'
  import { useUserStore } from '@/stores/userStore'
  import { useOrgStore } from '@/stores/orgStore'
  import { useNewChatStore } from '@/stores/chatStore'
  import { storeToRefs } from 'pinia'
  import LongArrowLeftIcon from './icons/LongArrowLeftIcon.vue'
  import LongArrowRightIcon from './icons/LongArrowRightIcon.vue'
  import CloseIcon from './icons/CloseIcon.vue'
  import axios from '@/axiosInstance'
  import { useRoute, useRouter } from 'vue-router'
  import { formatDistanceToNow, parseISO } from 'date-fns'

  export default {
    name: 'SideNavigation',
    components: { LongArrowLeftIcon, LongArrowRightIcon, CloseIcon },
    setup() {
      const route = useRoute()
      const router = useRouter()

      const userStore = useUserStore()
      const orgStore = useOrgStore()
      const { user, chatMessages } = storeToRefs(userStore)
      const { org, connections } = storeToRefs(orgStore)
      const newChatStore = useNewChatStore()

      // isMobileDevice
      const isMobileDevice = ref(window.matchMedia('(max-width: 520px)').matches)

      // Watch for changes in the window width
      const mediaQuery = window.matchMedia('(max-width: 820px)')
      const handleMediaQueryChange = mediaQuery => {
        isMobileDevice.value = window.matchMedia('(max-width: 520px)').matches
      }

      // Set the initial state based on the window width
      handleMediaQueryChange(mediaQuery)

      // Watch for changes in the window width
      mediaQuery.addEventListener('change', handleMediaQueryChange)

      // state to handle the visibility of the banner on mobile
      const showSidebar = ref(!isMobileDevice.value)

      const toggleSidebar = () => {
        showSidebar.value = !showSidebar.value
      }

      const showBanner = ref(false)

      const showDropdown = ref(false)
      const toggleShowDropdown = () => {
        showDropdown.value = !showDropdown.value
      }
      const dropdownTrigger = ref(null)
      const dropdownMenu = ref(null)

      const handleClickedOutside = e => {
        // click outside profile dropdown
        if (
          dropdownTrigger.value &&
          !dropdownTrigger.value.contains(e.target) &&
          dropdownMenu.value &&
          !dropdownMenu.value.contains(e.target)
        ) {
          showDropdown.value = false
        }

        // click outside quick actions dropdown
        if (
          activeQuickActionsDropdown.value &&
          !document.getElementById(`quickactions-${activeQuickActionsDropdown.value}`).contains(e.target) &&
          !document.getElementById(`quickactions-dropdown-${activeQuickActionsDropdown.value}`).contains(e.target)
        ) {
          showActiveQuickActionsDropdown.value = false
          activeQuickActionsDropdown.value = null
        }
      }

      // central styling for navlinks to enable mass editing
      const linkIconStyles = ref(
        `cursor-pointer flex items-center p-2 text-sm font-normal text-gray-900 rounded-16 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-900 group w-full`
      )
      const linkStyles = ref('ml-3 visible')

      // watch for changes in the connection number
      watch(
        connections,
        val => {
          if (val.length === 0) {
            showBanner.value = true
          } else if (val.length > 1) {
            showBanner.value = false
          } else {
            showBanner.value = val[0].type === 'plugin'
          }
        },
        { immediate: true, deep: true }
      )

      // hide the sidebar when the route changes
      router.afterEach(() => {
        if (isMobileDevice.value) {
          showSidebar.value = false
        }
      })

      // Compute if the current route is the root route ("/") to conditionally render the new chat button
      const isRootRoute = computed(() => route.path === '/' || route.path === '/new')

      function newChat() {
        // remove questionRunning, ongoingChatId, and localMessages from sessionStorage
        sessionStorage.removeItem('questionRunning')
        sessionStorage.removeItem('ongoingChatId')
        sessionStorage.removeItem('localMessages')
        newChatStore.createNewChat()

        router.push('/new')
      }

      // creates a calculated property that clusters chatMessages by date
      const chatMessagesByDate = computed(() => {
        const chatMessagesByDate = {}

        // Filter out chatMessages that have been pinned
        const filteredChatMessages = chatMessages.value.filter(chatMessage => {
          return !chatMessage.pinned
        })

        // First order the chatMessages by date so that the newest messages are first
        filteredChatMessages.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })

        for (const chatMessage of filteredChatMessages) {
          const utcDate = parseISO(chatMessage.date)
          const date = formatDistanceToNow(utcDate, { addSuffix: true })

          if (!chatMessagesByDate[date]) {
            chatMessagesByDate[date] = []
          }
          chatMessagesByDate[date].push(chatMessage)
        }

        return chatMessagesByDate
      })

      const pinnedChatMessages = computed(() => {
        return chatMessages.value.filter(chatMessage => {
          return chatMessage.pinned
        })
      })

      onMounted(() => {
        initDropdowns()
        orgStore.getOrg()

        if (!chatMessages.value.length) {
          userStore.getChatMessages()
        }

        orgStore.getConnections(true).then(() => {
          if (orgStore.connections.length === 0) {
            showBanner.value = true
          } else if (orgStore.connections.length > 1) {
            showBanner.value = false
          } else {
            if (orgStore.connections[0].type === 'plugin') {
              showBanner.value = true
            }
          }
        })

        // Add event listener to handle clicks outside the dropdown
        document.addEventListener('click', handleClickedOutside)
      })

      onBeforeUnmount(() => {
        // Remove event listener
        document.removeEventListener('click', handleClickedOutside)
      })

      const pinChat = async chatMessage => {
        chatMessage.pinned = !chatMessage.pinned

        // update the chatMessage in the database
        await axios.post('/api/pinChat', {
          chat_id: chatMessage.id,
          pin: chatMessage.pinned,
        })
      }

      const activeQuickActionsDropdown = ref(null)
      const showActiveQuickActionsDropdown = ref(false)
      const quickActionsDropdownPosition = ref({
        top: 0,
        left: 0,
      })

      const showQuickActionsDropdownMenu = (chatMessage, event) => {
        if (activeQuickActionsDropdown.value === chatMessage.id) {
          showActiveQuickActionsDropdown.value = false
          activeQuickActionsDropdown.value = null
          return
        }

        activeQuickActionsDropdown.value = chatMessage.id
        showActiveQuickActionsDropdown.value = true

        // Get the position of the clicked button
        const buttonRect = event.target.getBoundingClientRect()

        if (buttonRect) {
          quickActionsDropdownPosition.value = {
            top: buttonRect.top - 65,
            left: buttonRect.left + window.scrollX + 40,
          }
        }
      }

      // share & schedule conversation
      const show_share_modal = ref(false)
      const share_chat_id = ref(null)
      const active_share_tab = ref('share')

      const handleShareConversation = chat_id => {
        share_chat_id.value = chat_id
        show_share_modal.value = true

        console.log('share_chat_id', share_chat_id.value)
      }

      const handleScheduleConversation = chat_id => {
        share_chat_id.value = chat_id
        active_share_tab.value = 'schedule'
        show_share_modal.value = true
      }

      // rename conversation
      const editTitleId = ref(null)
      const editTitle = ref(null)
      const renameConversation = async chat_id => {
        const title = editTitle.value
        try {
          const res = await axios.post('/api/change_title', {
            chat_id: chat_id,
            title: title,
          })

          if (res) {
            userStore.getChatMessages()
          }
        } catch (error) {
          console.log(error)
        } finally {
          editTitleId.value = null
        }
      }

      const handleQuickAction = (chatMessage, action) => {
        activeQuickActionsDropdown.value = null
        showActiveQuickActionsDropdown.value = false

        switch (action) {
          case 'pin':
            pinChat(chatMessage)
            break
          case 'rename':
            editTitleId.value = chatMessage.id
            editTitle.value = chatMessage.title
            // get the title element and focus on it
            const titleElement = document.getElementById(`title-${chatMessage.id}`)
            if (titleElement) {
              titleElement.focus()
              titleElement.click()
            }
            break
          case 'share':
            handleShareConversation(chatMessage.id)
            break
          case 'schedule':
            handleScheduleConversation(chatMessage.id)
            break
          default:
            break
        }
      }

      return {
        user,
        org,
        linkIconStyles,
        linkStyles,
        window,
        newChat,
        isRootRoute,
        router,
        chatMessagesByDate,
        formatDistanceToNow,
        pinChat,
        pinnedChatMessages,
        showSidebar,
        toggleSidebar,
        isMobileDevice,
        showBanner,
        route,
        showDropdown,
        toggleShowDropdown,
        dropdownTrigger,
        dropdownMenu,
        showQuickActionsDropdownMenu,
        activeQuickActionsDropdown,
        quickActionsDropdownPosition,
        showActiveQuickActionsDropdown,
        handleQuickAction,
        show_share_modal,
        share_chat_id,
        active_share_tab,
        renameConversation,
        editTitleId,
        editTitle,
      }
    },
    methods: {
      randomJoke() {
        const jokes = [
          'Why did I break up with my ex? I had too many relationship tables. But I will never let YOU down.',
          'Why did the data analyst get thrown out of the bar? They kept trying to normalize the drinks.',
          'What’s a data analyst’s favorite part of a joke? The punchline is statistically significant.',
          "Why don't data analysts tell time jokes? They don't want to deal with interval errors.",
          "How does a data analyst propose marriage? 'Will you be the y to my x?'",
          "You know what's my favorite drink? Bubble tea – because of all the floating points.",
          'Why did the data analyst stay calm during the zombie apocalypse? They knew it was just a cluster analysis gone wrong.',
          "How does a data analyst say goodbye? 'I'll catch you on the flip side of this bell curve.'",
          'Why do data analysts love the school playground? They get a kick out of the swings and roundabouts in the data.',
          "What did the data analyst say to the skeptical manager? 'I think you'll find these numbers quite significant.'",
          'Why do data analysts make terrible comedians? They always spoil the punchline by explaining the variance.',
          'How do data analysts fix a broken database? With table joins.',
          'There are two kinds of data scientists. 1.) Those who can extrapolate from incomplete data.',
          'Analysis is 80% preparing data, 19% complaining about preparing data and 1% about asking Dot.',
          'There are 10 kinds of people in this world. Those who understand binary and those who don’t.',
          'The data science motto: If at first, you don’t succeed; call it version 0.1',
          'A SQL query walks into a bar, walks up to two tables, and asks, “Can I join you?”',
          'An analyst walks into a bar, orders a beer, and orders 0 beers, 999999999 beers, -1 beers, a lizard, and a sfdeljknesv.',
          'Why should you take a data scientist with you into the jungle? Answer: They can take care of Python problems.',
          'Data is like people – interrogate it hard enough and it will tell you whatever you want to hear.',
          'You are so mean that your standard deviation is zero.',
          'Two random variables were talking in a bar. They thought they were being discreet, but I heard their chatter continuously.',
          'A statistician’s wife had twins. He was delighted. He rang the minister who was also delighted. “Bring them to church on Sunday and we’ll baptize them,” said the minister. “No,” replied the statistician. “Baptize one. We’ll keep the other as a control.”',
          'If all your friends jumped off a bridge, would you follow them? Machine Learning Algorithm: yes.',
          'I have a joke about deep learning, but I can’t explain it.',
          'I have a machine learning joke, but it is not performing as well on a new audience.',
        ]
        const index = Math.floor(Math.random() * jokes.length)
        return jokes[index]
      },
    },
  }
</script>

<style scoped>
  .sidebarHistoryElement {
    transition: all 0.2s ease-in-out;
  }

  .sidebarHistoryElement:hover .pinButton {
    display: block !important;
  }
</style>
