<template>
  <div class="my_container bg-white text-dark-text dark:text-white dark:bg-gray-950">
    <ChatComponent />
  </div>
</template>

<script>
  import ChatComponent from './components/ChatComponent.vue'
  import { onMounted } from 'vue'
  import { initModals } from 'flowbite'

  export default {
    name: 'ChatPage',
    components: {
      ChatComponent,
    },
    setup() {
      onMounted(() => {
        initModals()
      })
    },
  }
</script>

<style>
  .my_container {
    width: 100%;
    min-height: 100%;
    /*padding-top: 50px;*/
  }
</style>
