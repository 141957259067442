<template>
  <div class="p-4 rounded-xl flex flex-col gap-8" id="users" role="tabpanel" aria-labelledby="users-tab">
    <div>
      <h1 class="text-base font-semibold">Allow Other Registrations</h1>
      <div class="w-full max-w-[32rem] flex items-center justify-between gap-1 mt-1">
        <p class="">Allow users of the same domain ({{ org.id }}) to register</p>
        <ToggleButton :active="allowRegisteration" @update:active="$event => toggleRegistration($event)" />
      </div>
    </div>

    <div>
      <h1 class="text-base font-semibold">Handle New Users</h1>
      <div class="w-full max-w-[32rem] flex items-center justify-between gap-1 mt-1">
        <p class="">Default new users to admin role</p>
        <ToggleButton :active="newUserAdmin" @update:active="$event => toggleNewUserAdmin($event)" />
      </div>
      <div class="w-full max-w-[32rem] flex items-center justify-between gap-1 mt-2">
        <p class="">Assign new users to this group:</p>
        <select
          :value="newUserGroup"
          class="bg-gray-50 border border-gray-300 font-mono font-medium text-gray-900 text-xs rounded-xl focus:ring-blue-500 focus:border-blue-500 block p-1.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-max"
          @change="setNewUserGroup($event)"
        >
          <option v-for="(group, index) in allGroups" :key="index" :value="group">{{ group }}</option>
        </select>
      </div>
    </div>

    <div v-if="org.okta_active || org.azure_active">
      <h1 class="text-base font-semibold">Disable registrations and password login</h1>
      <div class="w-full max-w-[32rem] flex items-center justify-between gap-1 mt-1">
        <p class="">Prohibit users to register to your Dot instance and to use password based access</p>
        <button
          class="w-10 h-5 rounded-xl relative flex-shrink-0"
          :class="{
            'bg-blue-600 border-blue-300 border-2': disablePasswordLogin,
            'bg-gray-200 dark:bg-gray-700 border-blue-200': !disablePasswordLogin,
          }"
          @click="toggleDisablePasswordLogin"
        >
          <span
            class="w-4 h-4 rounded-full bg-white block absolute top-1/2 -translate-y-1/2 shadow"
            :class="{
              'left-[1px]': !disablePasswordLogin,
              'right-[1px]': disablePasswordLogin,
            }"
          ></span>
        </button>
      </div>
    </div>

    <div>
      <h1 class="text-base font-semibold">Invite Team Members</h1>
      <div class="mt-1">
        <label for="#inviteMembers" class="block mb-1">
          Invite other people to join your team. Separate emails with a space.
        </label>
        <div
          class="p-2.5 pr-5 relative w-full max-w-[32rem] border border-gray-300 bg-gray-50 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white focus-within:ring-blue-500 focus-within:border-blue-500 dark:focus-within:ring-blue-500 dark:focus-within:border-blue-500 rounded-xl overflow-hidden"
        >
          <div class="mb-2 flex items-center flex-wrap gap-1" v-if="usersForInvite.length">
            <span
              v-for="(user, index) in usersForInvite"
              @click="removeUserEmail(user)"
              :key="index"
              class="py-1 px-1.5 bg-gray-200 hover:bg-gray-300 dark:bg-gray-500 dark:hover:bg-gray-400 rounded flex items-center gap-1 w-max cursor-pointer"
            >
              {{ user }}
              <CloseIcon class="w-[14px]" />
            </span>
          </div>
          <input
            type="email"
            v-model="email"
            id="inviteMembers"
            autocomplete="off"
            @keyup.enter="addUserEmail(email)"
            @keyup.space="addUserEmail(email)"
            @change="addUserEmail(email)"
            :placeholder="usersForInvite.length ? 'Add another email' : 'Enter email address'"
            class="block w-full text-sm border-0 outline-none p-0 focus:ring-0 text-gray-900 dark:text-white bg-transparent"
          />
          <PlusIcon
            @click="addUserEmail(email)"
            class="absolute right-1 w-10 h-10 top-1/2 -translate-y-1/2 text-gray-400 dark:text-gray-500 dark:hover:text-white hover:text-gray-500 dark:hover:bg-gray-400 hover:bg-gray-200 rounded cursor-pointer"
          />
        </div>
        <div class="w-full max-w-[32rem] flex justify-end">
          <button
            @click="inviteUsers"
            :class="{
              'bg-blue-500 hover:bg-blue-600': usersForInvite.length || email.length,
              'bg-gray-300 dark:bg-gray-800 dark:text-gray-600 cursor-not-allowed font-medium':
                !(usersForInvite.length || email.length) || sendingInvite,
            }"
            :disabled="!(usersForInvite.length || email.length) || sendingInvite"
            class="py-2 px-6 mt-1 w-max text-white rounded-xl text-center inline-flex items-center"
          >
            {{ sendingInvite ? 'Sending...' : 'Send Invites' }}
            <svg
              class="w-5 h-5 ml-2 mr-1"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="relative shadow-md sm:rounded-xl">
      <div class="bg-white dark:bg-gray-950">
        <label for="table-search" class="sr-only">Search</label>
        <div class="flex justify-between items-center">
          <div class="relative pl-3">
            <svg
              class="w-5 h-5 text-gray-500 dark:text-gray-400 absolute pointer-events-none top-1/2 left-3 -translate-y-1/2"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <input
              type="text"
              id="table-search"
              v-model="search_users"
              @input="page_users = 1"
              class="block p-2 pl-6 text-sm text-gray-900 border-0 rounded-xl w-80 focus:ring-0 focus:outline-none focus:dark:text-white dark:bg-gray-950 dark:placeholder-gray-400 dark:text-white"
              placeholder="Search.."
            />
          </div>
          <button
            @click="downloadUserCSV"
            class="flex items-center px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-900 hover:dark:text-gray-100 rounded-md focus:outline-none ml-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 mr-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
            Download Users
          </button>
        </div>
      </div>
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead
          class="text-xs text-gray-700 uppercase border-t border-b border-gray-100 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-6 py-3 text-left">Email</th>
            <th scope="col" class="px-2 py-3 text-left">Role</th>
            <th scope="col" class="px-2 py-3 text-left">Groups</th>
            <th scope="col" class="px-2 py-3 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in filteredUserItems.slice((page_users - 1) * perPage_users, page_users * perPage_users)"
            :key="item.id"
            class="bg-white border-b dark:bg-gray-950 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-900"
            :data-testid="`user-${item.email}`"
          >
            <th
              scope="row"
              alt="item.name"
              class="pl-6 pr-2 py-4 text-xs max-w-sm block truncate font-medium whitespace-nowrap"
              :class="{
                'text-gray-500 dark:text-gray-500': !item.verified,
                'text-gray-900 dark:text-white': item.verified,
              }"
              v-tooltip="item.verified ? 'Active' : 'Inactive'"
            >
              {{ item.email }}
            </th>
            <td
              class="pl-2 pr-2 py-1 text-xs max-w-2xl font-mono truncate text-ellipsis font-medium text-gray-500 whitespace-nowrap dark:text-gray-400"
            >
              <select
                id="llm-model"
                data-test="a5706fd6-9097-4559-b13c-2d3c58f14e19"
                :value="item.role"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                @change="changeUserRole(item, $event)"
              >
                <option value="admin" data-test="37eeaff8-f1a4-4373-8b85-56fcdcf88b90">Admin</option>
                <option value="user" data-test="d79825dd-cb43-4cab-ab43-dbf84c28e32a">User</option>
              </select>
            </td>

            <td>
              <div class="flex items-center flex-row flex-wrap gap-1">
                <span
                  v-for="(group, index) in item.groups.filter(g => g !== item.id)"
                  @click="removeUserFromGroup(item, group)"
                  :key="index"
                  v-tooltip="`Remove ${group}`"
                  class="py-1 px-3 font-mono font-medium text-xs text-white bg-gray-950 hover:bg-gray-600 dark:text-dark-text dark:bg-white dark:hover:bg-gray-400 rounded-full flex items-center gap-1 w-max cursor-pointer"
                >
                  {{ group }}
                  <CloseIcon class="w-[14px]" />
                </span>
                <div v-if="item" class="grow">
                  <input
                    id="addGroups"
                    v-model="textInput[item.id]"
                    :placeholder="item.groups.length ? 'Add another group' : 'Add table to group'"
                    autocomplete="off"
                    class="block w-full text-xs border-0 font-mono font-medium outline-none p-0 pl-1 py-2 focus:ring-0 text-gray-900 dark:text-white bg-transparent"
                    type="text"
                    @keydown.tab.stop.prevent="addAutocomplete(item, $event, $event.target.value)"
                    @keyup="showSuggestion(item), addUserToGroup(item, $event, $event.target.value)"
                  />
                  <div v-if="autoCompPlaceholder[item.id]" class="clearit virtual-text">
                    <div class="hidden-text text-xs font-mono font-medium">{{ textInput[item.id] }}</div>
                    <input
                      :placeholder="autoCompPlaceholder[item.id]"
                      class="autocomplete text-xs font-mono font-medium"
                      type="text"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </td>
            <td
              class="py-1 text-center text-xs max-w-2xl font-mono font-medium text-gray-500 whitespace-nowrap dark:text-gray-400"
            >
              <!-- Dropdown button -->
              <div @click.stop class="relative">
                <button
                  @click="$refs[`actions-${item.id}`][0].classList.toggle('hidden')"
                  class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-xl hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                    />
                  </svg>
                </button>

                <!-- Dropdown menu -->
                <div
                  :ref="`actions-${item.id}`"
                  class="z-10 w-64 hidden absolute right-0 top-[calc(100% + 10px)] bg-white divide-y divide-gray-100 rounded-xl shadow w-44 dark:bg-gray-800 dark:divide-gray-700"
                >
                  <ul
                    class="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownMenuIconHorizontalButton"
                  >
                    <li>
                      <button
                        @click.stop="getApiToken(item), $refs[`actions-${item.id}`][0].classList.toggle('hidden')"
                        class="block w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Copy API Token
                      </button>
                    </li>
                    <li>
                      <button
                        @click.stop="deleteUser(item)"
                        class="block w-full px-4 py-2 text-red-700 dark:text-red-400 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        Delete User
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="flex flex-col sm:flex-row gap-2 sm:gap-0 items-center p-2 dark:bg-gray-950">
        <!-- Help text -->
        <span class="text-sm text-gray-500 dark:text-gray-400 sm:ml-2">
          Showing
          <span class="font-semibold text-gray-600 dark:text-white">
            {{ (page_users - 1) * perPage_users + 1 }}
          </span>
          to
          <span class="font-semibold text-gray-600 dark:text-white">
            {{ Math.min(page_users * perPage_users, filteredUserItems.length) }}
          </span>
          of
          <span class="font-semibold text-gray-600 dark:text-white">{{ filteredUserItems.length }}</span>
        </span>

        <div class="flex gap-2 ml-2 w-max shrink-0">
          <span class="text-sm text-gray-500 dark:text-gray-400">· Rows per page</span>
          <div class="relative z-10" ref="options">
            <span
              class="flex items-center w-max gap-2 bg-gray-100 dark:bg-gray-800 px-4 cursor-pointer rounded"
              @click.stop="showOptions = !showOptions"
            >
              {{ perPage_users }}
            </span>
            <div
              v-if="showOptions"
              class="absolute bottom-6 right-0 w-max bg-white dark:bg-gray-800 shadow-md rounded-md overflow-hidden z-10"
            >
              <div
                v-for="item in itemsPerPageOptions"
                :key="item"
                class="w-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                @click="(perPage_users = item), (showOptions = false)"
              >
                {{ item }}
              </div>
              <div
                class="w-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                @click="(perPage_users = filteredUserItems.length), (showOptions = false)"
              >
                All
              </div>
            </div>
          </div>
        </div>

        <div class="inline-flex sm:ml-auto xs:mt-0">
          <!-- Buttons -->
          <button
            @click="page_users = Math.max(page_users - 1, 1)"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-gray-100 rounded-l-2xl hover:bg-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Prev
          </button>
          <button
            @click="page_users = Math.min(page_users + 1, parseInt(users.length / perPage_users) + 1)"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-gray-100 rounded-r-2xl hover:bg-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            Next
            <svg
              aria-hidden="true"
              class="w-5 h-5 ml-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="h-8"></div>
    <div class="border-red-500 dark:border-red-400 rounded-2xl border p-5">
      <h1 class="text-base font-semibold">Danger Zone</h1>
      <label for="#deleteOrg" class="block mb-4">Delete this organization and all associated data forever.</label>
      <div class="w-full max-w-[32rem] flex items-center justify-between gap-1 mt-1">
        <button
          id="deleteOrg"
          class="bg-red-500 hover:bg-red-600 text-white py-2 px-6 rounded-2xl"
          data-modal-target="deleteModal"
          data-modal-toggle="deleteModal"
        >
          Delete Organization
        </button>
      </div>
    </div>

    <!-- Delete Modal -->
    <div
      id="deleteModal"
      tabindex="-1"
      aria-hidden="true"
      class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
      <div class="relative w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-xl shadow dark:bg-gray-800">
          <!-- Modal header -->
          <div class="flex items-start justify-between p-4 px-6 border-b rounded-t dark:border-gray-700">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">Delete Confirmation</h3>
            <button
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="deleteModal"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-6">
            <p class="text-gray-700 dark:text-gray-300">Are you sure you want to delete this organization?</p>
          </div>
          <!-- Modal footer -->
          <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-700">
            <button
              data-modal-hide="deleteModal"
              type="button"
              class="ml-auto text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-xl border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              Cancel
            </button>
            <button
              data-modal-hide="deleteModal"
              type="button"
              @click="deleteOrganization"
              :disabled="loading_delete"
              class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 text-center inline-flex items-center font-medium rounded-xl text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PlusIcon from '../icons/PlusIcon.vue'
  import CloseIcon from '../icons/CloseIcon.vue'
  import ToggleButton from '../ToggleButton.vue'
  import axios from '@/axiosInstance'
  import { useOrgStore } from '@/stores/orgStore'
  import { storeToRefs } from 'pinia'
  import { ref, onMounted, watch, computed } from 'vue'
  import { useGlobalStore } from '@/stores/globalStore'
  import { initModals } from 'flowbite'

  export default {
    name: 'UsersTab',
    components: {
      PlusIcon,
      CloseIcon,
      ToggleButton,
    },
    setup() {
      const globalStore = useGlobalStore()
      const notify = globalStore.notify
      const orgStore = useOrgStore()
      const { org, groups } = storeToRefs(orgStore)

      const textInput = ref({})
      const autoCompPlaceholder = ref({})
      const users = ref([])
      const loading_delete = ref(false)

      console.log(org.value)

      const allowRegisteration = ref(org.value.allow_registration)
      const newUserAdmin = ref(org.value.new_user_admin)
      const newUserAddedToGroup = ref(org.value.new_user_group) || ref(false)
      const disablePasswordLogin = ref(org.value.disable_password_login)
      const newUserGroup = ref(org.value.new_user_group) || ref('all_users')

      watch(
        () => org.value,
        () => {
          allowRegisteration.value = org.value.allow_registration
          newUserAdmin.value = org.value.new_user_admin
          newUserAddedToGroup.value = org.value.new_user_group
          disablePasswordLogin.value = org.value.disable_password_login
          newUserGroup.value = org.value.new_user_group || 'all_users'
        }
      )

      onMounted(() => {
        initModals()
      })

      const getUsers = () => {
        axios
          .get('/api/get_users', { withCredentials: true })
          .then(res => {
            users.value = res.data
          })
          .catch(err => {
            console.log(err)
          })
      }

      const showSuggestion = doc => {
        if (!textInput.value[doc.id] || textInput.value[doc.id].length === 0) {
          autoCompPlaceholder.value[doc.id] = ''
          return
        }

        const possibleMatches = groups.value.filter(group => {
          return group.toLowerCase().startsWith(textInput.value[doc.id].toLowerCase()) && !doc.groups.includes(group)
        })

        if (possibleMatches.length > 0) {
          autoCompPlaceholder.value[doc.id] = possibleMatches[0].replace(textInput.value[doc.id], '')
        } else {
          autoCompPlaceholder.value[doc.id] = ''
        }
      }

      const addAutocomplete = (doc, event, group) => {
        if (!group) {
          group = textInput[doc.id]
        }

        group += autoCompPlaceholder.value[doc.id]
        group = group.trim().toLowerCase()

        if (!doc.groups.includes(group)) {
          axios
            .post('/api/add_user_to_group', { email: doc.email, group: group }, { withCredentials: true })
            .then(() => {
              getUsers()
              // Clear input
              textInput.value = {}
              autoCompPlaceholder.value = {}
            })
            .catch(err => {
              console.log(err)
            })
        }
      }

      const deleteOrganization = () => {
        loading_delete.value = true
        axios
          .delete('/api/org', {}, { withCredentials: true })
          .then(() => {
            window.location.href = '/'
          })
          .catch(err => {
            console.log(err)
            loading_delete.value = false
          })
      }

      const perPage_users = ref(10)
      const page_users = ref(1)
      const search_users = ref('')

      const filteredUserItems = computed(() => {
        return users.value.filter(item => {
          return item.email && item.email.toLowerCase().includes(search_users.value.toLowerCase())
        })
      })

      const downloadUserCSV = () => {
        // Start by creating a new CSV file and its header
        // We want to include email, name, role, groups, and verified status
        // groups is an array, so we need to join it into a string and escape any commas
        const csvContent = `data:text/csv;charset=utf-8,Email,Name,Role,Groups,Verified\n${filteredUserItems.value
          .map(user => {
            return `${user.email},${user.name},${user.role},${user.groups.filter(group => group !== user.id).join(';')},${user.verified ? 'Active' : 'Inactive'}`
          })
          .join('\n')}`

        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', 'users.csv')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }

      const showOptions = ref(false)

      const itemsPerPageOptions = ref([10, 25, 50, 100])

      return {
        allowRegisteration,
        newUserAdmin,
        disablePasswordLogin,
        org,
        textInput,
        autoCompPlaceholder,
        users,
        showSuggestion,
        addAutocomplete,
        getUsers,
        notify,
        newUserAddedToGroup,
        newUserGroup,
        loading_delete,
        deleteOrganization,
        downloadUserCSV,
        filteredUserItems,
        perPage_users,
        page_users,
        search_users,
        showOptions,
        itemsPerPageOptions,
      }
    },
    data() {
      return {
        email: '',
        usersForInvite: [],
        sendingInvite: false,
        message: '',
        isSuccess: true,
      }
    },

    computed: {
      allGroups() {
        let groups = ['new_users', 'all_users']
        this.users.forEach(user => {
          let userGroups = Object.values(user.groups)
          // remove the group name that is the same as the user's id
          userGroups = userGroups.filter(group => group !== user.id)
          if (userGroups.length > 0) {
            userGroups.forEach(group => {
              if (!groups.includes(group)) {
                groups.push(group)
              }
            })
          }
        })
        return groups
      },
    },

    mounted() {
      this.getUsers()

      document.title = 'Users · Settings · Dot'
    },

    methods: {
      deleteUser(user) {
        user.loading = true
        axios
          .post('/api/delete_user', { email: user.email }, { withCredentials: true })
          .then(() => {
            this.getUsers()
            user.loading = false
          })
          .catch(err => {
            console.log(err)
            user.loading = false
          })
      },
      getApiToken(user) {
        axios
          .post('/api/get_api_token', { user_id: user.id }, { withCredentials: true })
          .then(res => {
            this.notify.success('API Token copied to clipboard')
            navigator.clipboard.writeText(res.data)
          })
          .catch(err => {
            console.log(err)
            this.notify.error('Error creating API Token')
          })
      },
      toggleRegistration(value) {
        this.allowRegisteration = value
        axios.post('/api/set_org_registration', { value }, { withCredentials: true })
      },
      toggleNewUserAdmin(value) {
        this.newUserAdmin = value
        axios.post('/api/set_new_user_admin', { value }, { withCredentials: true })
      },
      setNewUserGroup(event) {
        this.newUserGroup = event.target.value
        axios.post('/api/set_new_user_group', { value: this.newUserGroup }, { withCredentials: true })
      },
      toggleDisablePasswordLogin() {
        this.disablePasswordLogin = !this.disablePasswordLogin

        axios.post('/api/set_disable_password_login', { value: this.disablePasswordLogin }, { withCredentials: true })
      },
      addUserEmail(emailString) {
        const validateEmail = email => {
          // Use a more robust regex for email validation
          return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        }

        const emails = emailString
          .split(/[\s,;\n]+/) // Split on spaces, commas, semicolons, and newlines
          .map(email => email.trim())
          .filter(email => validateEmail(email)) // Validate and filter

        this.usersForInvite = [...new Set([...this.usersForInvite, ...emails])] // Combine and remove duplicates
        this.email = ''
      },
      removeUserEmail(email) {
        this.usersForInvite = this.usersForInvite.filter(user => user !== email)
      },
      inviteUsers() {
        this.addUserEmail(this.email)
        this.sendingInvite = true
        axios
          .post('/api/send_invitations', { emails: this.usersForInvite }, { withCredentials: true })
          .then(res => {
            this.usersForInvite = []
            this.getUsers()
            this.showNotification('Invitations have been sent', true)
            this.sendingInvite = false
          })
          .catch(err => {
            console.log(err)
            this.showNotification('Error sending invitations', false)
            this.sendingInvite = false
          })
      },
      changeUserRole(user, event) {
        axios
          .post('/api/change_user_role', { email: user.email, role: event.target.value }, { withCredentials: true })
          .then(() => {
            this.getUsers()
          })
          .catch(err => {
            console.log(err)
          })
      },
      addUserToGroup(user, event) {
        // Only execute if keyup event is enter, space or comma
        if (event.keyCode !== 13 && event.keyCode !== 32 && event.keyCode !== 188) {
          return
        }

        const group = event.target.value.trim().toLowerCase()

        axios
          .post('/api/add_user_to_group', { email: user.email, group: group }, { withCredentials: true })
          .then(() => {
            this.getUsers()
            // Clear input
            this.textInput = {}
          })
          .catch(err => {
            console.log(err)
          })
      },
      removeUserFromGroup(user, group) {
        axios
          .post('/api/remove_user_from_group', { email: user.email, group }, { withCredentials: true })
          .then(() => {
            this.getUsers()
          })
          .catch(err => {
            console.log(err)
          })
      },
      showNotification(message, isSuccess) {
        if (isSuccess) {
          this.notify.success(message)
        } else {
          this.notify.error(message)
        }
      },
    },
  }
</script>
