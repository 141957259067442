<!-- Settings Drawer -->
<template>
  <div
    class="container px-[24px] pt-2 pb-8 overflow-y-scroll bg-white text-dark-text dark:text-white dark:bg-gray-950 w-full"
  >
    <div class="mb-4 border-b border-gray-100 dark:border-gray-900">
      <ul class="flex flex-wrap -mb-px text-base font-medium text-center" id="myTab">
        <li class="mr-2" role="presentation">
          <button
            class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            :class="{
              'border-blue-500 text-blue-500 dark:border-blue-500': activeTab === 'connections',
              'dark:border-gray-600': activeTab !== 'connections',
            }"
            id="connection-tab"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
            @click="changeTab('connections')"
          >
            Connections
          </button>
        </li>
        <li class="mr-2" role="presentation">
          <button
            class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            :class="{
              'border-blue-500 text-blue-500 dark:border-blue-500': activeTab === 'modules',
              'dark:border-gray-600': activeTab !== 'modules',
            }"
            id="connection-tab"
            type="button"
            role="tab"
            aria-controls="modules"
            aria-selected="false"
            @click="changeTab('modules')"
          >
            Modules
          </button>
        </li>
        <li class="mr-2" role="presentation">
          <button
            class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            :class="{
              'border-blue-500 text-blue-500 dark:border-blue-500': activeTab === 'billing',
              'dark:border-gray-600': activeTab !== 'billing',
            }"
            id="billing-tab"
            type="button"
            role="tab"
            aria-controls="billing"
            aria-selected="false"
            @click="changeTab('billing')"
          >
            Billing
          </button>
        </li>
        <li class="mr-2" role="presentation">
          <button
            class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            :class="{
              'border-blue-500 text-blue-500 dark:border-blue-500': activeTab === 'users',
              'dark:border-gray-600': activeTab !== 'users',
            }"
            id="users-tab"
            type="button"
            role="tab"
            aria-controls="users"
            aria-selected="false"
            @click="changeTab('users')"
          >
            Users
          </button>
        </li>
      </ul>
    </div>
    <div id="myTabContent">
      <ConnectionsTab v-if="activeTab === 'connections'" />
      <BillingTab v-else-if="activeTab === 'billing'" @show-notification="showNotification" />
      <UsersTab v-else-if="activeTab === 'users'" />
      <ModulesTab v-else-if="activeTab === 'modules'" />
    </div>
  </div>

  <!-- notification toast messages bind it to changes to variable message-->
</template>

<script>
  import { onMounted, ref } from 'vue'
  // import { initDrawers, initTabs } from "flowbite";
  import BillingTab from './components/BillingTab.vue'
  import ModulesTab from './components/ModulesTab.vue'
  import UsersTab from './components/usersTab/UsersTab.vue'
  import ConnectionsTab from './components/connectionsTab/ConnectionsTab.vue'
  import { useRouter } from 'vue-router'
  import { useGlobalStore } from './stores/globalStore'

  export default {
    name: 'SettingsPage',
    components: {
      UsersTab,
      BillingTab,
      ConnectionsTab,
      ModulesTab,
    },
    setup() {
      const globalStore = useGlobalStore()
      const notify = globalStore.notify
      const router = useRouter()

      const changeTab = tab => {
        router.push(`/settings/${tab}`)
      }

      return {
        changeTab,
        notify,
      }
    },
    data() {
      return {
        message: '',
        isSuccess: false,
      }
    },
    methods: {
      showNotification({ message, isSuccess }) {
        if (isSuccess) {
          this.notify.success(message)
        } else {
          this.notify.error(message)
        }
      },
    },
    computed: {
      activeTab() {
        const path = this.$route.path.replace('/settings', '')
        if (path === '/' || path === '/connections') {
          return 'connections'
        } else if (path === '/modules') {
          return 'modules'
        } else if (path === '/billing') {
          return 'billing'
        } else if (path === '/users') {
          return 'users'
        } else {
          return 'connections'
        }
      },
    },
  }
</script>
